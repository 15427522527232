import React from "react";
import { Skeleton, SkeletonText, Stack } from "@chakra-ui/react";

const TransactionLoader = ({ showText = true }: { showText?: boolean }) => {
  return (
    <Stack padding="1" bg="white" rounded="md">
      {showText && <SkeletonText mt="4" noOfLines={2} spacing="4" />}
      <Skeleton height="40px" />
    </Stack>
  );
};

export function TransactionLoaderList({
  itemsToRender,
  showText,
}: {
  itemsToRender: number;
  showText?: boolean;
}) {
  return (
    <>
      {Array(itemsToRender)
        .fill(1)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TransactionLoader key={index} showText={showText} />
        ))}
    </>
  );
}

export default TransactionLoader;
