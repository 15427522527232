import { Flex, Heading, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import useMfa from "../hooks/useMfa";

export type LoginRefType = {
  loginReference: string;
};

const MfaVerify = () => {
  const location = useLocation();
  const data: LoginRefType = location.state as LoginRefType;
  const navigate = useNavigate();

  const { data: verifyData, isLoading, confirmyOtpCode } = useMfa();
  const [verifyCode, setVerifyCode] = useState("");

  useEffect(() => {
    if (verifyData) {
      localStorage.setItem("verified", "true");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [verifyData]);

  return (
    <Flex align="center" flexDirection="column" justify="center">
      <Heading>Verification Code</Heading>
      <p style={{ textAlign: "center", marginBottom: 15 }}>
        Enter the verification code from your <br /> authenticator app
      </p>
      <Flex align="center" flexDirection="column" justify="center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            confirmyOtpCode({
              code: verifyCode,
              loginReference: data.loginReference,
            });
          }}
        >
          <Input
            bg="#fff"
            autoComplete="false"
            type="number"
            name="code"
            id="code"
            style={{
              height: 40,
              marginBottom: 15,
              textAlign: "center",
              width: "100%",
            }}
            onChange={(e) => setVerifyCode(e.target.value)}
          />
          <Button
            type="submit"
            title="Validate"
            isLoading={isLoading}
            isDisabled={isLoading}
            style={{ width: "100%" }}
          />
        </form>
      </Flex>
    </Flex>
  );
};

export default MfaVerify;
