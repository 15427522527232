import { useEffect, useState } from "react";
import { Bonds } from "../types/API.interface";
import useAPI from "./useAPI";

const UseBonds = () => {
  const { getBonds } = useAPI();
  const [bonds, setBonds] = useState<Array<Bonds>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    let mounted = true;
    const getBondsData = async () => {
      try {
        const bonds = await getBonds();
        setLoading(true);
        setBonds(bonds?.data?.data);
        setLoading(false);
      } catch (e) {
        setError(false);
      }
    };
    getBondsData();
    return () => {
      mounted = false;
    };
  }, [getBonds]);

  return { bonds, loading, error };
};

export default UseBonds;
