import React from "react";
import {
  Box,
  Divider,
  Flex,
  Grid,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { useQuery } from "react-query";
import PageTitle from "../components/PageTitle";
import BankCard from "../components/Cards/BankCard";
import TextInput from "../components/Form/FormInput";
import Button from "../components/Button";
import TransactionItem from "../components/TransactionItem";
import useAPI from "../hooks/useAPI";
import { Data } from "../types/API.interface";
import { TransactionLoaderList } from "../components/TransactionLoader";
import { LoadingItemList } from "../components/LoadingItem";

type Params = { address: string };

const WalletDashboard = () => {
  const { address } = useParams<Params>();
  const { getWalletAddress } = useAPI();

  const { data, isLoading } = useQuery(
    ["wallet", address],
    () => getWalletAddress(address),
    {
      enabled: address !== "0",
      refetchOnWindowFocus: false,
    },
  );

  const wallet = data?.data?.data[0] as unknown as Data;

  return (
    <Stack spacing="4rem">
      <PageTitle
        title="Wallet"
        icon={AiFillDashboard}
        walletAddress={wallet?.walletTransactions[0]?.address}
      />

      {isLoading ? (
        <Grid templateColumns={{ md: "repeat(3, 1fr)" }} gap={6}>
          <LoadingItemList itemsToRender={3} />
        </Grid>
      ) : (
        <Grid
          templateColumns={{ md: "repeat(4, 1fr)" }}
          gap={6}
          w={{ md: 135 }}
        >
          <BankCard
            title="Wallet Funds"
            amount={wallet?.walletFunds}
            filter="none"
            minH={{ md: 48 }}
            mr={0}
            h={{ md: 48 }}
          />
          <BankCard
            title="Interest Earned"
            amount={wallet?.interestEarned}
            filter="none"
            minH={{ md: 48 }}
            mr={0}
            h={{ md: 48 }}
          />
          <BankCard
            title="Average transaction size"
            amount={wallet?.averageTransactionSize}
            filter="none"
            minH={{ md: 48 }}
            mr={0}
            h={{ md: 48 }}
          />
        </Grid>
      )}

      <Stack spacing="2rem">
        <Flex align="center" justify="space-between">
          <Box>
            <Text fontSize={{ md: "xl" }} fontWeight="semibold">
              Wallets transactions
            </Text>
          </Box>
          <HStack w={{ md: 108 }} spacing="1rem" align="end">
            <TextInput label="From" type="date" borderWidth={0} />
            <TextInput label="To" type="date" borderWidth={0} />

            <Button title="Enter" w={{ md: 48 }} />
          </HStack>
        </Flex>

        <Stack divider={<Divider />} bg="white" rounded="md">
          {isLoading ? (
            <Stack spacing="1rem">
              <TransactionLoaderList itemsToRender={4} />
            </Stack>
          ) : (
            wallet?.walletTransactions?.map((item) => (
              <TransactionItem key={item.id} {...item} />
            ))
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WalletDashboard;
