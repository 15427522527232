import React from "react";
import { Grid, Skeleton, Stack } from "@chakra-ui/react";
import PieChartCard from "../Cards/PieChartCard";
import { useFetch } from "../../hooks/useFetchEventSource";
import { LoadingItemList } from "../LoadingItem";
import BondMetricsSection from "../sections/BondMetricsSection";
import { useStore } from "../../store";

const CustodianAndBondMetricsSection = () => {
  const { bondId } = useStore((state) => state);
  const { status, data } = useFetch({ url: "custodian-backers", bondId });
  const { status: bondsMetricsStatus, data: bondsMetrics } = useFetch({
    url: "bonds-metrics",
    bondId,
  });
  return (
    <Grid templateColumns={{ md: "40% 60%" }} gap={3}>
      <PieChartCard loading={status === "fetching"} custodianBackers={data} />
      {bondsMetricsStatus === "fetching" ? (
        <Stack spacing="0.5rem">
          <Skeleton height="8" w={{ md: 24 }} />
          <Grid templateColumns={{ md: "repeat(4, 1fr)" }} gap={2}>
            <LoadingItemList itemsToRender={7} />
          </Grid>
        </Stack>
      ) : (
        <BondMetricsSection metrics={bondsMetrics} />
      )}
    </Grid>
  );
};

export default CustodianAndBondMetricsSection;
