/* eslint-disable */
import React from "react";
import { Box, Link, Stack } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AuthPageTitle from "../components/AuthPageTitle";
import Button from "../components/Button";
import useForm from "../hooks/useForm";
import FormInput from "../components/Form/FormInput";
import { useLogin } from "../utils/api";
import useAuth from "../hooks/useAuth";
import { useStore } from "../store";

export type LocationProps = {
  state: {
    from: Location;
  };
};

function Login() {
  // const toast = useToast();
  const { isAuthenticated, signOut } = useAuth();

  const navigate = useNavigate();
  // const location = useLocation() as unknown as LocationProps;
  // const from = location.state?.from?.pathname || "/auth/mfa";
  const verified = localStorage.getItem("verified") || false;
  const from = "/auth/mfa";
  const confirmUrl = "/auth/verify";

  React.useEffect(() => {
    if (isAuthenticated().token && verified) {
      navigate(from);
    }
  }, []);

  const [mutateAsync, { isLoading: isSubmitting }] = useLogin({
    onSuccess: (vars) => {
      if (!vars?.data.shouldCompleteMfa) {
        localStorage.setItem("token", vars?.token as any);
        localStorage.setItem("refresh_token", vars?.refresh_token as any);
        localStorage.setItem("user", JSON.stringify(vars.data));
        useStore.setState({ token: vars.token, user: vars.user });
        navigate(from, { state: "login" });
      } else {
        let activeMfaData = vars?.data.mfaData;
        let foundMfaOtp: boolean = false;
        foundMfaOtp = activeMfaData.some(
          (mfa: any) => mfa.type === "authenticator",
        );
        if (foundMfaOtp) {
          navigate(confirmUrl, {
            state: { loginReference: vars?.data.loginReference },
          });
        }
      }
    },
  });

  const onSubmit = async (values: any) => {
    await mutateAsync(values);
  };

  const { renderForm } = useForm({
    onSubmit,
    defaultValues: {
      log: "",
      password: "",
    },
  });

  return renderForm(
    <Box w={{ base: 80, md: 108 }} color="brand.primary">
      <Stack spacing="3rem">
        <AuthPageTitle title="Welcome back," subtitle="Login to your account" />

        <Stack spacing={{ base: "1rem", md: "2rem" }} mt={{ base: 8, md: 10 }}>
          <FormInput
            name="log"
            label="Email or Username"
            placeholder="ejara@gmail.com"
          />

          <FormInput
            name="password"
            label="Password"
            placeholder="********"
            type="password"
          />

          <Button
            type="submit"
            title="Login"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          />
        </Stack>

        <Link
          as={RouterLink}
          to="/auth/register"
          _hover={{ textDecor: "none" }}
          fontSize="lg"
          fontWeight="semibold"
          _focus={{ outline: "none" }}
        >
          Don’t have an account? Create one now
        </Link>
      </Stack>
    </Box>,
  );
}

export default Login;
