import create from "zustand";
import { persist } from "zustand/middleware";

export const useStore = create(
  persist(
    (set: (arg0: (state: any) => any) => any, get) => ({
      bondId: null,
      getAllBonds: [],
      isLoading: false,
      error: null,
      user: null,
      token: null,
      signupData: null,
      setSignupData: (data: any) => set(() => ({ signupData: data })),
    }),
    {
      name: "bonds-storage",
    },
  ),
);
