import { Grid, GridItem, Stack } from "@chakra-ui/react";
import { AiFillDashboard } from "react-icons/ai";
import { DateRange } from "rsuite/DateRangePicker";
import BarChartCard from "../components/Cards/BarChartCard";
import CustodianAndBondMetricsSection from "../components/home/CustodianAndBondMetrics";
import EjaraFundedWallet from "../components/home/EjaraFundedWallet";
import PageTitle from "../components/PageTitle";
import LiveWalletSection from "../components/sections/LiveWalletSection";
import useDateFilter from "../hooks/useDateFilter";
import { useFetch } from "../hooks/useFetchEventSource";
import { useStore } from "../store";

export default function Home() {
  const { bondId } = useStore((state) => state);

  const { value, setValue, dateFilter } = useDateFilter();
  const { startDate, endDate } = dateFilter;

  const { status: walletsMetricsStatus, data: walletsMetrics } = useFetch({
    url: "wallets-transactions",
    bondId,
    limit: 3,
    startDate,
    endDate,
  });
  // const { data: monthlyIncome } = useFetch({
  //   url: "monthly-prepaid-interests",
  //   bondId,
  // });
  // console.log("MONTHLY INCOME>>>", monthlyIncome)

  const { status: monthlyFundedWalletStatus, data: monthlyFundedWallet } =
    useFetch({ url: "monthly-funded-wallets", bondId });

  // console.log("MONTHLY FUNDED WALLET>>>", monthlyFundedWallet)

  const wallet = walletsMetrics[0] as any;
  // const labels = monthlyIncome.map((i) => Object.keys(i))[0];
  // const values = monthlyIncome.map((i) => Object.values(i))[0];

  // const monthlyIncomeData = {
  //   labels,
  //   datasets: [
  //     {
  //       data: values,
  //       backgroundColor: "#505AE9",
  //     },
  //   ],
  // };
  const monthlyFundedWalletData = {
    labels: monthlyFundedWallet.map((i) => Object.keys(i))[0],
    datasets: [
      {
        data: monthlyFundedWallet.map((i) => Object.values(i))[0],
        backgroundColor: "#ff8000",
      },
    ],
  };

  return (
    <Stack spacing="1.5rem" overflow="hidden">
      <PageTitle title="Dashboard" icon={AiFillDashboard} />
      <Stack spacing="2rem" w={{ md: "100%", xl: "98%" }}>
        <CustodianAndBondMetricsSection />

        <LiveWalletSection />
      </Stack>
      <Grid templateColumns="repeat(5, 1fr)" gap={2} alignItems="center">
        <GridItem colSpan={2}>
          <BarChartCard
            title="Funded Wallets"
            data={monthlyFundedWalletData}
            loading={monthlyFundedWalletStatus === "fetching"}
            textAlign="center"
          />
        </GridItem>
        <GridItem colSpan={3}>
          <EjaraFundedWallet
            walletsMetricsStatus={walletsMetricsStatus}
            wallet={wallet}
            setValue={setValue}
            value={value as DateRange}
          />
        </GridItem>
      </Grid>
    </Stack>
  );
}

Home.auth = true;
