import React from "react";
import { Box } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
  },
  scales: {
    y: {
      display: true, // Hide Y axis labels
    },
  },
};

export function BarChart({ data }: { data: any }) {
  return (
    <Box
      height={{ "3xl": 44, "4xl": 48, "5xl": 56, "6xl": 64 }}
      w="full"
      position="relative"
    >
      <Bar options={options} data={data} color="#ff8000" />
    </Box>
  );
}
