import {
  Box,
  Grid,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiActivity } from "react-icons/fi";
import { DateRange } from "rsuite/DateRangePicker";
import CityTable from "../components/CityTable";
import useDateFilter from "../hooks/useDateFilter";
// eslint-disable-next-line import/no-cycle
import BarChartCard from "../components/Cards/BarChartCard";
import TransactionsCard from "../components/Cards/TransactionsCard";
import PageTitle from "../components/PageTitle";
import { useFetch } from "../hooks/useFetchEventSource";
// eslint-disable-next-line import/no-cycle
import CountryTable from "../components/CountryTable";
import { TransactionLoaderList } from "../components/TransactionLoader";
import { useStore } from "../store";
// eslint-disable-next-line import/no-cycle
import { Button, TablePagination } from "@mui/material";
import HoldersDetailsTable, {
  HoldersDataType,
} from "../components/HoldersDetailsTable";
import Aggregates from "../components/aggregates/Aggregates";

export type Transactions = {
  id: number;
  address: string;
  amount: number;
  transactiontype: string;
  transactiondate: string;
  latitude: number;
  longitude: number;
};

export type Country = {
  activeWallets: number;
  averageDepositPerUser: number;
  country: string;
  totalValueLocked: number;
};

export type HoldersData = {
  username: string;
  gender: string;
  country: string;
  balance: number;
};

export type CountriesActivityMetrics = {
  transactions: Transactions[];
  countryActivityMetrics: Country[];
};

export default function Activity() {
  const { bondId } = useStore((state) => state);
  const { value, setValue, dateFilter } = useDateFilter();
  const { startDate, endDate } = dateFilter;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = useState(0);
  const [transactionOffset, setTransactionOffset] = useState(0);

  const { status: holdersDetailsStatus, data: holdersDetails } = useFetch({
    url: "holders-details",
    bondId,
    limit: rowsPerPage,
    offset: offset,
  });

  const { status: walletsMetricsStatus, data: walletsMetrics } = useFetch({
    url: "wallets-transactions",
    bondId,
    limit: 10,
    offset: transactionOffset,
  });

  const { status: aggregateStatus, data: aggregateMetrics } = useFetch({
    url: "total-deposits-breakdown",
    bondId,
  });

  const { status: monthlyIncomeStatus, data: monthlyIncome } = useFetch({
    url: "monthly-prepaid-interests",
    bondId,
  });

  const { status: monthlyFundedWalletStatus, data: monthlyFundedWallet } =
    useFetch({ url: "monthly-funded-wallets", bondId });

  const { status: isCountryLoading, data: countriesActivities } = useFetch({
    url: "countries-activities",
    bondId,
    startDate,
    endDate,
  });

  // console.log(isCountryLoading, countriesActivities);

  const country = countriesActivities as unknown as CountriesActivityMetrics;

  const holdersDataRow = holdersDetails as unknown as HoldersDataType;

  const { countryActivityMetrics } = country;
  const transactions = walletsMetrics[0] as any;

  const wallet = walletsMetrics[0] as any;

  const labels = monthlyIncome.map((i) => Object.keys(i))[0];
  const values = monthlyIncome.map((i) => Object.values(i))[0];

  const monthlyIncomeData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: "#ff8000",
      },
    ],
  };
  const monthlyFundedWalletData = {
    labels: monthlyFundedWallet.map((i) => Object.keys(i))[0],
    datasets: [
      {
        data: monthlyFundedWallet.map((i: any) => Object.values(i))[0],
        backgroundColor: "#505AE9",
      },
    ],
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * rowsPerPage);

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeNext = () => {
    setTransactionOffset((prev) => prev + 10);
  };
  const handleChangePrev = () => {
    setTransactionOffset((prev) => prev - 10);
  };

  return (
    <Stack spacing="4rem">
      <PageTitle title="EJARA Activity" icon={FiActivity} />
      <Aggregates aggregate={aggregateMetrics} status={aggregateStatus} />
      <Grid templateColumns={{ md: "55% 45%" }} gap={{ md: 10 }}>
        <Stack spacing="1.4rem">
          <Tabs>
            <TabList>
              <Tab>Africa</Tab>
              <Tab>City</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {isCountryLoading === "fetching" ? (
                  <Box
                    bg="white"
                    h={{ md: 120 }}
                    overflowY="scroll"
                    w="full"
                    p={6}
                  >
                    <TransactionLoaderList
                      itemsToRender={10}
                      showText={false}
                    />
                  </Box>
                ) : (
                  <CountryTable countries={countryActivityMetrics} />
                )}
              </TabPanel>
              <TabPanel>
                {walletsMetricsStatus === "fetching" ? (
                  <Box
                    bg="white"
                    h={{ md: 120 }}
                    overflowY="scroll"
                    w="full"
                    p={6}
                  >
                    <TransactionLoaderList
                      itemsToRender={10}
                      showText={false}
                    />
                  </Box>
                ) : (
                  <CityTable wallet={wallet} />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
          {holdersDetailsStatus === "fetching" ? (
            <Box bg="white" h={{ md: 120 }} overflowY="scroll" w="full" p={6}>
              <TransactionLoaderList itemsToRender={10} showText={false} />
            </Box>
          ) : (
            <>
              <HoldersDetailsTable holders={holdersDataRow.holders} />
              <TablePagination
                component="div"
                count={holdersDataRow.totalCount ?? 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
          <HStack display={{ base: "block", "2xl": "flex" }} spacing={10}>
            <BarChartCard
              data={monthlyIncomeData}
              title="Pre-Paid interest"
              textAlign="center"
              loading={monthlyIncomeStatus === "fetching"}
            />
            <BarChartCard
              title="Funded Wallets"
              data={monthlyFundedWalletData}
              loading={monthlyFundedWalletStatus === "fetching"}
              textAlign="center"
              mt={{ base: 10, "2xl": 0 }}
            />
          </HStack>
        </Stack>
        {walletsMetricsStatus === "fetching" ? (
          <Box bg="white" h={{ md: 120 }} overflowY="scroll" w="full" p={6}>
            <TransactionLoaderList itemsToRender={10} showText={false} />
          </Box>
        ) : (
          <>
            <Stack spacing="3rem">
              <TransactionsCard
                transactions={transactions?.walletTransactions}
                isLoading={isCountryLoading === "fetching"}
                value={value as DateRange}
                setValue={setValue}
              />
              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: "space-between" }}
                marginTop={2}
              >
                <Button
                  variant="outlined"
                  onClick={handleChangePrev}
                  disabled={transactionOffset === 0}
                >
                  Prev
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleChangeNext}
                  disabled={transactions?.walletTransactions.length === 0}
                >
                  Next
                </Button>
              </Stack>
            </Stack>
          </>
        )}
      </Grid>
    </Stack>
  );
}

Activity.auth = true;
