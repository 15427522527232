import React from "react";
import { Box, Stack, useToast } from "@chakra-ui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AuthPageTitle from "../components/AuthPageTitle";
import TextInput from "../components/Form/FormInput";
import Button from "../components/Button";

interface IFormInput {
  email_address: string;
}

function ForgotPassword() {
  const toast = useToast();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async () => {
    toast({
      title: "Successfully logged in",
      position: "bottom-right",
      status: "success",
      duration: 5000,
      isClosable: false,
    });
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  };

  return (
    <Box w={{ base: 80, md: 108 }} color="brand.primary">
      <Stack>
        <AuthPageTitle
          title="Forgotten password?"
          subtitle="Enter your email to reset your password"
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            spacing={{ base: "1rem", md: "2rem" }}
            mt={{ base: 8, md: 10 }}
          >
            <Controller
              control={control}
              name="email_address"
              render={({ field }) => (
                <TextInput
                  label="Email"
                  placeholder="ejara@gmail.com"
                  required
                  type="email"
                  {...field}
                />
              )}
            />

            <Button
              type="submit"
              title="Reset password"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            />
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}

export default ForgotPassword;
