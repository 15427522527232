import { Divider, Stack } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import { Status } from "../../hooks/useFetchEventSource";
import { Transactions } from "../../pages/activity";
import FilterAndButton from "../FilterAndButton";
import TransactionItem, { TransactionItemProps } from "../TransactionItem";
import { TransactionLoaderList } from "../TransactionLoader";

type Props = {
  walletsMetricsStatus: Status;
  wallet: {
    walletTransactions: Transactions[];
  };
  value: DateRange;
  setValue: Dispatch<SetStateAction<DateRange | any>>;
};

const EjaraFundedWallet = (props: Props) => {
  const { walletsMetricsStatus, wallet, value, setValue } = props;

  return (
    <Stack spacing="1rem">
      <FilterAndButton
        wallet={wallet?.walletTransactions}
        setValue={setValue}
        value={value}
      />

      <Stack
        divider={<Divider />}
        bg="white"
        rounded="lg"
        spacing="0.5rem"
        height="auto"
        // h={{ md: 64, "5xl": 72, "6xl": 80 }}
      >
        {walletsMetricsStatus === "fetching" ? (
          <Stack spacing="1rem">
            <TransactionLoaderList itemsToRender={4} />
          </Stack>
        ) : (
          wallet?.walletTransactions?.map(
            (transaction: TransactionItemProps) => (
              <TransactionItem key={transaction.id} {...transaction} />
            ),
          )
        )}
      </Stack>
    </Stack>
  );
};

export default EjaraFundedWallet;
