import axios from "axios";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import env from "../environments";
import useAuth from "./useAuth";

export type Status = "fetching" | "fetched" | "error" | "stale";

const cache = {};

const fetchNewToken = async () => {
  const headers: any = {
    "Content-Type": "application/json",
    "client-id": process.env.REACT_APP_CLIENT_ID,
    "api-key": process.env.REACT_APP_API_KEY,
  };
  try {
    const rs = await axios.post(
      "https://prodbox-nellys-coin.ejaraapis.xyz/api/v1/auth/reset-token",
      {
        refreshToken: localStorage.getItem("refresh_token"),
      },
      { headers },
    );
    // console.log(rs);
    const newToken = rs?.data?.data.token;
    localStorage.setItem("token", newToken);
  } catch (error) {
    // console.log(error);
  }
};

export const useFetch = (payload: {
  url: unknown;
  bondId?: string | number | any;
  startDate?: string | any;
  endDate?: string | any;
  limit?: number;
  offset?: number;
}) => {
  // const navigate = useNavigate()
  const { isAuthenticated } = useAuth();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState<Status>("stale");
  // console.log(cache);

  useEffect(() => {
    if (!payload.url) return;

    const filterDateURL = `${process.env.REACT_APP_API_URL}/${payload.url}?bondId=${payload.bondId}&startDate=${payload.startDate}&endDate=${payload.endDate}`;
    const filterIDULR = `${process.env.REACT_APP_API_URL}/${payload.url}?bondId=${payload.bondId}`;
    const filterURL = `${process.env.REACT_APP_API_URL}/${payload.url}`;

    const getUrl =
      !payload.startDate && !payload.endDate ? filterIDULR : filterDateURL;

    let dataUrl =
      !payload.bondId && !payload.startDate && !payload.endDate
        ? filterURL
        : getUrl;

    // if (payload.limit && payload.offset) {
    //   dataUrl = `${dataUrl}&limit=${payload.limit}&offset=${payload.offset}`;
    //   console.log(dataUrl);
    // }

    const fetchData = async () => {
      // const ctrl = new AbortController();
      setStatus("fetching");
      // @ts-ignore
      if (cache[payload.url]) {
        // @ts-ignore
        setData(cache[payload.url]);
        setStatus("fetched");
      }

      // const axiosInstance = http(`${dataUrl}`);
      const axiosInstance = () => {
        const instance = axios.create({
          headers: {
            "client-key": env.tokenizedBondenvironment.clientKey,
            "client-secret": env.tokenizedBondenvironment.clientSecret,
            Authorization: `Bearer ${isAuthenticated().token}`,
          },
        });
        return instance;
      };

      await axiosInstance()
        .get(dataUrl, {
          params: { limit: payload.limit, offset: payload.offset },
        })
        .then((res) => {
          setData(res.data.data);
          setStatus("fetched");
        })
        .catch(async (err) => {
          if (err.response.status === 401) {
            await fetchNewToken();
            window.location.reload();
          } else if (
            err.response.status >= 400 &&
            err.response.status < 500 &&
            err.response.status !== 429
          ) {
            setStatus("error");
          }
        });

      /*
      await fetchEventSource(dataUrl, {
        headers: {
          "client-key": process.env.REACT_APP_TOKENIZED_CLIENT_KEY as string,
          "client-secret": process.env
            .REACT_APP_TOKENIZED_CLIENT_SECRET as string,
          Authorization: `Bearer ${isAuthenticated().token}`,
        },
        signal: ctrl.signal,
        openWhenHidden: true,
        async onopen(response) {
          if (
            response.ok &&
            response.headers.get("content-type") === EventStreamContentType
          ) {
            // console.log("good");
          } else if (response.status === 401) {
            await fetchNewToken();
            window.location.reload();
            // throw new RetriableError();
          } else if (
            response.status >= 400 &&
            response.status < 500 &&
            response.status !== 429
          ) {
            setStatus("error");
            // client-side errors are usually non-retriable:
            throw new FatalError();
          } else {
            setStatus("error");
            throw new RetriableError();
          }
        },
        onmessage(event) {
          if (event.event === "FatalError") {
            setStatus("error");
            throw new FatalError(event.data);
          } else {
            // @ts-ignore
            let parsedData: SetStateAction<never[]> = [];
            // @ts-ignore
            if (event.data) {
              parsedData = JSON.parse(event.data);
            }
            // @ts-ignore
            // if (cache[url]?.id !== bondId) {
            // @ts-ignore
            // cache[url] = null;
            // }
            // @ts-ignore
            // cache[url] = parsedData;
            // @ts-ignore
            // cache[url].id = bondId;
            // console.log(parsedData)
            // console.log(parsedData);
            setData(parsedData);
            setStatus("fetched");
          }
        },
        onclose() {
          // if the server closes the connection unexpectedly, retry:
          setStatus("error");
          throw new RetriableError();
        },
        onerror(err) {
          if (err instanceof FatalError) {
            setStatus("error");
            throw err; // rethrow to stop the operation
          } else {
            // do nothing to automatically retry. You can also
            // return a specific retry interval here.
          }
        },
      });
      */
    };
    fetchData();
  }, [
    isAuthenticated,
    payload.url,
    payload.bondId,
    payload.startDate,
    payload.endDate,
    payload.limit,
    payload.offset,
  ]);

  // isAuthenticated, url, bondId, startDate, endDate

  return { data, status };
};
