import React, {
  forwardRef,
  PropsWithoutRef,
  ComponentPropsWithoutRef,
} from "react";
import { useFormContext } from "react-hook-form";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import {
  ComponentWithAs,
  FormErrorMessage,
  Icon,
  IconProps,
  InputLeftElement,
  Link,
  // InputProps,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export interface LabeledTextFieldProps
  extends ComponentPropsWithoutRef<typeof Input> {
  /** Field name. */
  name: string;
  /** Field label. */
  label?: string;
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number" | "tel" | "file";
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  labelProps?: ComponentPropsWithoutRef<"label">;
  leftElement?: boolean;
  rightElement?: boolean;
  icon?: ComponentWithAs<"svg", IconProps>;
  props?: ComponentPropsWithoutRef<typeof Input>;
  password?: boolean;
  required?: boolean;
}
export const FormInput = forwardRef<HTMLInputElement, LabeledTextFieldProps>(
  (
    {
      label,
      outerProps,
      icon,
      labelProps,
      name,
      leftElement,
      rightElement,
      password,
      required,
      ...props
    },
    ref,
  ) => {
    const {
      register,
      formState: { isSubmitting, errors },
    } = useFormContext();
    const error = Array.isArray(errors[name])
      ? // @ts-ignore
        errors[name].join(", ")
      : errors[name]?.message || errors[name];
    return (
      <FormControl ref={ref} isRequired={required} {...outerProps}>
        {label && (
          <FormLabel fontSize="sm" fontWeight="bold" {...labelProps}>
            {label}
          </FormLabel>
        )}
        <InputGroup>
          {leftElement && (
            <InputLeftElement>
              <Icon as={icon} color="primary.500" />
            </InputLeftElement>
          )}
          <Input
            height={{ base: 12, md: 16 }}
            borderBottomWidth={1}
            borderColor="brand.primary"
            bg="white"
            _placeholder={{ color: "brand.primary", opacity: 0.3 }}
            isDisabled={isSubmitting}
            {...register(name)}
            {...props}
          />
          {rightElement && (
            <InputRightElement>
              <Icon as={icon} color="primary.500" />
            </InputRightElement>
          )}
        </InputGroup>
        {password && (
          <Link
            as={RouterLink}
            textDecor="underline"
            textAlign="right"
            display="block"
            mt={3}
            fontWeight="semibold"
            to="/auth/forgot-password"
          >
            Forgot password?
          </Link>
        )}
        {error && (
          <FormErrorMessage fontSize="sm" role="alert" color="red.500">
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  },
);
FormInput.displayName = "FormInput";

export default FormInput;
