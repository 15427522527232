import React from "react";
import {
  Divider,
  Flex,
  Icon,
  Link,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BsArrowLeft, BsGraphUp } from "react-icons/bs";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import PageTitle from "../components/PageTitle";
import PropertyItem from "../components/PropertyItem";
import useAPI from "../hooks/useAPI";
import { TransactionHash, Transactions } from "../types/API.interface";

type Params = { id: string };

export default function Transaction() {
  const { id } = useParams<Params>();
  const { getTransaction, getTransactionHash } = useAPI();
  const [, setTransactionData] = React.useState<TransactionHash>([]);

  const newId = Number(id);

  const { data, isLoading } = useQuery(
    ["transactions", newId],
    () => getTransaction(newId),
    {
      enabled: newId !== 0,
      refetchOnWindowFocus: false,
    },
  );

  const transaction = data?.data?.data as unknown as Transactions;

  const blockchainHash = transaction?.blockchainhash;

  React.useEffect(() => {
    const fetchHash = async () => {
      const hash = await getTransactionHash(blockchainHash);
      setTransactionData(hash as TransactionHash);
    };
    fetchHash();
  }, [blockchainHash, getTransactionHash]);

  // const { data: transactionData } = useQuery(
  //   ["transactionHash", blockchainHash],
  //   () => getTransactionHash(blockchainHash),
  //   {
  //     enabled: blockchainHash !== "0",
  //     refetchOnWindowFocus: false,
  //   },
  // );

  // const transactionHash = transactionData[0]?.level as unknown as TransactionHash;

  const shortDate = moment(transaction?.datetime).format("LLL");

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="center"
        height="100vh"
        width="100vw"
        direction="column"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Stack spacing="4rem">
      <PageTitle title="Transaction" icon={BsGraphUp} />

      <Stack spacing="2rem">
        <Link as={RouterLink} to="/wallets">
          <Icon as={BsArrowLeft} />
        </Link>

        <Flex>
          <Stack spacing="1.5rem" mr={8}>
            <Text fontSize={{ md: "xl" }} fontWeight="bold">
              General
            </Text>
            <Stack
              divider={<Divider borderColor="blue.200" />}
              bg="white"
              rounded="md"
              p={8}
              w={{ md: 95 }}
              h={{ md: 56 }}
            >
              <PropertyItem name="Date & Time" value={shortDate} />
              <PropertyItem name="Status" value={transaction?.status} />
              <PropertyItem name="Block" value={709992} />
              <Link
                href={`https://tzkt.io/${transaction.blockchainhash}`}
                _hover={{ textDecor: "none" }}
                _focus={{ outline: "none" }}
                background="#ff8000"
                p={2}
                color="#fff"
                isExternal
                ml={52}
                w="40%"
                rounded="5px"
              >
                View on explorer
              </Link>
              {/* <PropertyItem name="Confirmation" value="29" /> */}
              {/* <PropertyItem name="RPC Position" value="3 / 1 / 0" /> */}
            </Stack>
          </Stack>

          <Stack spacing="1.5rem" mr={8}>
            <Text fontSize={{ md: "xl" }} fontWeight="bold">
              Transaction Content
            </Text>
            <Stack
              divider={<Divider borderColor="blue.200" />}
              bg="white"
              rounded="md"
              p={8}
              w={{ md: 117 }}
              minH={{ md: 48 }}
            >
              <PropertyItem
                name="Type"
                value={`Bond ${transaction?.transactiontype}`}
              />
              <PropertyItem name="Sender" value={transaction?.sender} />
              <PropertyItem name="Receiver" value={transaction?.receiver} />
              <PropertyItem
                name="Amount"
                value={`${transaction?.amount} CFA`}
              />
            </Stack>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
}

Transaction.auth = true;
