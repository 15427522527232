import { BrowserRouter } from "react-router-dom";
import { APIProvider } from "../context/APIContext";
import { AuthProvider } from "../context/AuthContext";
import Router from "../routes";
import ErrorBoundary from "../utils/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <APIProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </APIProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
