import React from "react";
import {
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatDollar } from "../utils/formatCurrency";
// eslint-disable-next-line import/no-cycle
import { Country } from "../pages/activity";

type Props = {
  countries: Country[];
};
const CountryTable = (props: Props) => {
  const { countries } = props;

  return (
    <Table
      variant="simple"
      bg="white"
      w="full"
      maxH={{ md: 120 }}
      overflowY="scroll"
      rounded="md"
    >
      <Thead bg="brand.primary" h={{ md: 16 }}>
        <Tr>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            Country
          </Th>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            Active Wallets
          </Th>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            Average Deposit Per User
          </Th>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            Total Value Locked
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {countries?.map(
          ({
            country,
            activeWallets,
            averageDepositPerUser,
            totalValueLocked,
          }) => (
            <Tr key={country}>
              <Td>{country}</Td>
              <Td>{activeWallets}</Td>
              <Td>
                <HStack spacing="0.1rem">
                  <Text>
                    {averageDepositPerUser
                      ? formatDollar(averageDepositPerUser)
                      : 0}
                  </Text>
                  <Text fontSize="xs" pb={3}>
                    CFA
                  </Text>
                </HStack>
              </Td>
              <Td>
                <HStack spacing="0.1rem">
                  <Text>
                    {totalValueLocked ? formatDollar(totalValueLocked) : 0}
                  </Text>
                  <Text fontSize="xs" pb={3}>
                    CFA
                  </Text>
                </HStack>
              </Td>
            </Tr>
          ),
        )}
      </Tbody>
    </Table>
  );
};

export default CountryTable;
