import React from "react";
import {
  Box,
  Grid,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
} from "@chakra-ui/react";
import { BsArrowLeft, BsFillPersonFill } from "react-icons/bs";
import { Link as RouterLink } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import TextInput from "../components/Form/FormInput";
import FormPhone from "../components/Form/FormPhone";
import Button from "../components/Button";

export default function ProfileEdit() {
  return (
    <Stack spacing="2rem">
      <PageTitle title="Account" icon={BsFillPersonFill} />
      <Stack spacing="3rem">
        <HStack spacing="1rem">
          <Link as={RouterLink} to="/profile" _hover={{ textDecor: "none" }}>
            <Icon as={BsArrowLeft} />
          </Link>
          <Heading as="h3" fontSize={{ base: "2xl", md: "4xl" }}>
            Edit Personal information
          </Heading>
        </HStack>
        <form>
          <Stack spacing="2rem">
            <Grid
              templateColumns={{ md: "repeat(2, 1fr)" }}
              gap={6}
              w={{ md: 130 }}
            >
              <TextInput label="First & Last Name" placeholder="John Doe" />
              <TextInput
                label="Email"
                placeholder="ejara@gmail.com"
                type="email"
              />
              <FormPhone label="Phone Number" name="" />
              <TextInput
                label="New Password"
                placeholder="********"
                name="new_password"
              />
              <TextInput label="Confirm New Password" placeholder="********" />
            </Grid>
            <Box>
              <Button title="Login" w={{ base: "100%", md: 95 }} />
            </Box>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
}

ProfileEdit.auth = true;
