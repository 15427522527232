import React from "react";
import {
  BoxProps,
  Box,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BiMoney } from "react-icons/bi";
import { formatDollar } from "../../utils/formatCurrency";

type BankCardProps = BoxProps & {
  title?: string;
  amount?: number;
  blockchain?: number;
  count?: number;
  btnTitle?: string;
  wallet?: boolean;
  id?: string;
  onClick?: () => void;
  icon?: any;
};

function BankCard(props: BankCardProps) {
  const {
    title,
    amount,
    blockchain,
    btnTitle,
    wallet,
    id,
    onClick,
    count,
    icon,
    ...rest
  } = props;

  return (
    <Stack
      spacing={{ xl: "3rem" }}
      bg="white"
      p={{ md: 4, xl: 6 }}
      rounded="md"
      // filter="drop-shadow(0px 2px 20px rgba(0,0,0,0.1))"
      minW={{ md: 20, lg: 40, xl: 60, "2xl": 56, "5xl": 72, "6xl": 80 }}
      // w={{ md: 20, lg: 40, xl: 60 }}
      h={{ md: 20, lg: 48, xl: 60, "2xl": 56, "5xl": 72, "6xl": 80 }}
      justifyContent="space-between"
      {...rest}
    >
      <Box>
        <Flex
          background="#E5E5E5"
          width={{ md: 8, xl: 12 }}
          h={{ md: 8, xl: 12 }}
          pl={2}
          rounded="full"
          alignItems="center"
          justifyItems="center"
        >
          <Icon
            as={icon || BiMoney}
            boxSize={{ md: 4, xl: 7 }}
            color="#ff8000"
          />
        </Flex>
        <Text fontSize="sm" color="#505AE9" pt={4}>
          {title}
        </Text>
      </Box>
      {wallet ? (
        <Stack fontWeight="semibold" color="#000">
          <Text fontSize={{ md: "lg", lg: "2xl" }}>{amount}</Text>
        </Stack>
      ) : count ? (
        <Text
          fontWeight="semibold"
          fontSize={{ md: "lg", lg: "2xl" }}
          color="#000"
        >
          {count}
        </Text>
      ) : (
        <Stack spacing="0.01rem">
          <HStack spacing="0.1rem" fontWeight="semibold" color="#000">
            <Text fontSize={{ xl: "2xl" }}>
              {amount ? formatDollar(amount) : null}
            </Text>
            <Text fontSize="xs" pb={3}>
              CFA
            </Text>
          </HStack>
        </Stack>
      )}
    </Stack>
  );
}

export default BankCard;
