import React from "react";
import { HStack, Stack } from "@chakra-ui/react";
import { BsFillPersonFill } from "react-icons/bs";
import PageTitle from "../components/PageTitle";
import InfoCard from "../components/Cards/InfoCard";
import useAuth from "../hooks/useAuth";

export default function Profile() {
  const { isAuthenticated } = useAuth();
  const { user } = isAuthenticated();

  // console.log("user", user);

  return (
    <Stack spacing="3rem">
      <PageTitle title="Account" icon={BsFillPersonFill} />

      <HStack display={{ base: "block", md: "flex" }} spacing={{ md: "2rem" }}>
        <InfoCard
          title="Personal information"
          cardSubtitle={user?.email_address}
          cardTitle={`${user?.first_name} ${user?.last_name}`}
          // address="tz109876...N78IIh"
          // rightIcon={<Icon as={MdContentCopy} />}
        />
      </HStack>
    </Stack>
  );
}

Profile.auth = true;
