import React from "react";
import {
  Heading,
  HStack,
  Icon,
  IconButton,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { RiMenu4Line } from "react-icons/ri";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import WalletTextCopy from "./WalletTextCopy";
import { useStore } from "../store";

type Props = {
  icon: any;
  title: string;
  walletAddress?: string;
};
function PageTitle(props: Props) {
  const { getAllBonds, bondId } = useStore((state) => state);

  const [state, setState] = React.useState(bondId);
  const navigate = useNavigate();

  const { icon, title, walletAddress } = props;

  return (
    <Stack
      borderBottomWidth={1}
      borderBottomColor="gray.300"
      pb={4}
      color="brand.primary"
    >
      <HStack align="start" justify="space-between">
        <Stack>
          <IconButton
            display={{ base: "block", md: "none" }}
            aria-label="menu"
            icon={<Icon as={RiMenu4Line} />}
            variant="ghost"
          />
          <HStack spacing="1rem">
            <Icon as={icon} boxSize={12} />
            <Stack spacing="-0.5rem">
              <Heading>{title}</Heading>
              {walletAddress && (
                <WalletTextCopy walletAddress={walletAddress!} />
              )}
              <Text>{moment(new Date()).format("LLL")}</Text>
            </Stack>
          </HStack>
        </Stack>

        <HStack spacing="1rem">
          <Text fontWeight="semibold">Switch bonds</Text>
          <Select
            defaultValue={state}
            onChange={(e) => {
              const id = Number(e.target.value);
              setState(id);
              const bond = getAllBonds.find(
                (bond: { id: string }) => Number(bond.id) === id,
              );
              navigate(`/bonds/${bond?.slug ?? "all"}`, {
                replace: true,
              });
              useStore.setState({ bondId: id });
              window.location.reload();
            }}
            w={{ md: 40 }}
            borderColor="primary"
            _hover={{ borderColor: "primary" }}
            _focus={{ borderColor: "primary" }}
            fontWeight="semibold"
          >
            <option value="all">All</option>
            {getAllBonds.map((item: { id: number; name: string }) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </HStack>
      </HStack>
    </Stack>
  );
}
export default PageTitle;
