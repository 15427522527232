import { Grid, GridItem, Image } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

import Logo from "../assets/media/ejara.png";

function AuthLayout() {
  // const { pathname } = useLocation();

  return (
    <Grid templateColumns={{ md: "40% 60%" }} h="100vh">
      <GridItem
        bg="brand.primary"
        display="flex"
        alignItems="center"
        justifyContent="center"
        h={{ base: 32, md: "100%" }}
      >
        <Image src={Logo} alt="Ejara Logo" />
      </GridItem>
      <GridItem
        pos="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        h={{ base: "80vh", md: "100%" }}
      >
        {/* {pathname !== "/auth/login" && pathname !== "auth/register" && (
          <Link
            as={RouterLink}
            to="/auth/login"
            _hover={{ textDecor: "none" }}
            _focus={{ outline: "none" }}
            display="flex"
            alignItems="center"
            pos="absolute"
            left={{ base: 3, md: 10 }}
            top={{ base: -32, md: 10 }}
            fontWeight={500}
            color={{ base: "white", md: "brand.primary" }}
            fontSize={{ md: "lg" }}
          >
            <Icon as={BsArrowLeft} mr={2} /> Back to Login
          </Link>
        )} */}
        <Outlet />
      </GridItem>
    </Grid>
  );
}

export default AuthLayout;
