export const AUTH = process.env.REACT_APP_AUTH_URL as string;
export const API = process.env.REACT_APP_API_URL as string;
export const HASH = process.env.REACT_APP_HASH_API_URL as string;

export type EnvironmentType = "auth" | "api" | "hash";

type Environments = "LOCAL" | "STAGING" | "PRODUCTION";

// eslint-disable-next-line no-shadow
export enum ENVIRONMENT {
  local = "LOCAL",
  staging = "STAGING",
  production = "PRODUCTION",
}

export const EnvironmentApiUrls: Record<EnvironmentType, string> = {
  auth: AUTH,
  api: API,
  hash: HASH,
};

export function getEnvironmentApiUrl(env: EnvironmentType): string {
  return EnvironmentApiUrls[env];
}

// @ts-ignore
export const APP_ENV: Environments = process.env.REACT_APP_ENV;

export const isLocal = () => APP_ENV === ENVIRONMENT.local;
export const isProduction = () => APP_ENV === ENVIRONMENT.production;
export const isStaging = () => APP_ENV === ENVIRONMENT.staging;
