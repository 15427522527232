import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { Status } from "../hooks/useFetchEventSource";
import { formatDollar } from "../utils/formatCurrency";
import { FundType } from "../types/API.interface";

type Props = {
  datas: FundType[];
  walletsMetricsStatus: Status;
};
const TransactionTable = (props: Props) => {
  const { datas, walletsMetricsStatus } = props;
  // @ts-ignore
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Wallet</Th>
            <Th>Funds</Th>
            <Th>Interest</Th>
          </Tr>
        </Thead>
        <Tbody>
          {walletsMetricsStatus === "fetching" ? (
            <Flex align="center" justify="center" h={122}>
              <Spinner />
            </Flex>
          ) : (
            datas?.map((data: any) => (
              <Tr>
                <Td w={{ md: 32 }}>
                  <Text>{data?.address}</Text>
                </Td>
                <Td textAlign="right">
                  {data?.funds && formatDollar(data?.funds)}
                </Td>
                <Td textAlign="right">{data?.prepaidInterest.toFixed(2)}</Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
export default TransactionTable;
