import React, { useMemo } from "react";

import {
  AuthContextType,
  AuthenticatedType,
  LoginData,
} from "../types/Auth.interface";

export const AuthContext = React.createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: any) {
  const isAuthenticated = (): AuthenticatedType => {
    const localToken = localStorage.getItem("token");
    const localUser = localStorage.getItem("user");
    if (localToken && localUser) {
      return { token: localToken, user: JSON.parse(localUser) as LoginData };
    }
    return { token: "", user: {} as LoginData };
  };

  const token = useMemo(() => isAuthenticated().token, []);

  const signOut = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("verified");
    window.location.reload();
  };

  const provider = useMemo(
    (): AuthContextType => ({
      isAuthenticated,
      token,
      signOut,
    }),
    [token],
  );

  return (
    <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>
  );
}
