import {
  Flex,
  Grid,
  Icon,
  Link,
  Stack,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { FaChartBar, FaCoins } from "react-icons/fa";
import { GiPayMoney, GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { IoMdEye } from "react-icons/io";
import { MdAccountBalanceWallet, MdOutlinePointOfSale } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import Button from "../Button";
import BankCard from "../Cards/BankCard";

type Props = {
  wallet: {
    totalDepositors: number;
    totalWithdrawers: number;
    totalHolders: number;
    medianDepositSize: number;
    averageDepositSize: number;
    totalHoldersBalance: number;
    totalHoldersWithdrawableBalance: number;
    wallets: {
      address: string;
      funds: number;
      prepaidInterest: number;
    }[];
    valueOfFundedWallets: number;
    walletTransactions: {
      id: number;
      address: string;
      amount: number;
      transactiontype: string;
      transactiondate: string;
      longitude: number;
      latitude: number;
    };
  };
  hasButton?: boolean;
  width?: StackProps["width"];
  hasDepositSize?: boolean;
};

const LiveWalletSectionMain = (props: Props) => {
  const { wallet, hasButton = false, width, hasDepositSize } = props;

  // console.log(wallet);
  return (
    <Stack pos="relative" spacing="1rem" w={width}>
      <Flex align="center" justify="space-between">
        <Text fontWeight="bold" fontSize={{ md: "sm", xl: "lg" }} m={0}>
          Live Wallet Metrics
        </Text>
        {hasButton && (
          <Link as={RouterLink} to="/wallets" _hover={{ textDecor: "none" }}>
            <Button
              leftIcon={<Icon as={IoMdEye} boxSize={{ sm: 3, xl: 5 }} />}
              title="View All"
              colorScheme="orangeColorScheme"
              size="sm"
              fontSize={{ md: "xs", xl: "md" }}
              h={{ sm: 6, xl: 10 }}
            />
          </Link>
        )}
      </Flex>
      <Grid
        templateColumns={{
          md: hasDepositSize ? "repeat(5, 1fr)" : "repeat(5, 1fr)",
        }}
        gap={hasDepositSize ? 6 : 4}
      >
        <BankCard
          icon={GiPayMoney}
          title="Total Depositors"
          amount={wallet?.totalDepositors}
          wallet
        />
        <BankCard
          icon={GiReceiveMoney}
          title="Total Withdrawers"
          amount={wallet?.totalWithdrawers}
          wallet
        />
        <BankCard
          icon={GiTakeMyMoney}
          title="Total Holders"
          count={wallet?.totalHolders}
        />
        <BankCard
          icon={MdAccountBalanceWallet}
          title="Total Holders Balance"
          amount={wallet?.totalHoldersBalance}
        />
        <BankCard
          icon={MdOutlinePointOfSale}
          title="Total Withdrawable Balance"
          amount={wallet?.totalHoldersWithdrawableBalance}
        />
        {hasDepositSize && (
          <>
            <BankCard
              icon={FaCoins}
              title="Median Deposit size"
              amount={wallet?.medianDepositSize}
            />
            <BankCard
              icon={FaChartBar}
              title="Average Deposit size"
              amount={wallet?.averageDepositSize}
            />
          </>
        )}
      </Grid>
    </Stack>
  );
};

export default LiveWalletSectionMain;
