import React from "react";
import {
  Flex,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { utils, writeFile } from "xlsx";
import Button from "./Button";
import { formatDollar } from "../utils/formatCurrency";
// eslint-disable-next-line import/no-cycle
import { HoldersData } from "../pages/activity";

export interface HoldersDataType {
  holders: HoldersData[];
  totalCount?: number;
}

// type Props = {
//   holdersData: HoldersData[];
// };
const HoldersDetailsTable = (props: HoldersDataType) => {
  let holdersData = props.holders;

  const handleExport = () => {
    const wb = utils.book_new();
    const dataJson = utils.json_to_sheet(holdersData);
    utils.book_append_sheet(wb, dataJson, "Ejara Holders Details");
    const wbk = "Ejara Holders Details.xlsx";
    writeFile(wb, wbk);
  };

  return (
    <Stack spacing="1.4rem">
      <Flex align="center" justify="space-between" px={2} mt={8}>
        <Text fontWeight="bold" fontSize={{ md: "sm", xl: "lg" }}>
          Holders Details
        </Text>
        <Button
          title="Export"
          h={10}
          disabled={holdersData?.length <= 0}
          onClick={handleExport}
        />
      </Flex>
      <Stack bg="#fff" h={{ md: 120 }} overflowY="scroll" w="full" mb={10}>
        <Table
          variant="simple"
          bg="white"
          w="full"
          maxH={{ md: 120 }}
          overflowY="scroll"
          rounded="md"
        >
          <Thead bg="brand.primary" h={{ md: 16 }}>
            <Tr>
              <Th
                color="white"
                fontSize="md"
                textTransform="capitalize"
                fontWeight="semibold"
              >
                Username
              </Th>
              <Th
                color="white"
                fontSize="md"
                textTransform="capitalize"
                fontWeight="semibold"
              >
                Gender
              </Th>
              <Th
                color="white"
                fontSize="md"
                textTransform="capitalize"
                fontWeight="semibold"
              >
                Country
              </Th>
              <Th
                color="white"
                fontSize="md"
                textTransform="capitalize"
                fontWeight="semibold"
              >
                Balance
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {holdersData?.map(({ username, gender, country, balance }) => (
              <Tr key={username}>
                <Td>{username}</Td>
                <Td>{gender}</Td>
                <Td>{country}</Td>
                <Td>
                  <HStack spacing="0.1rem">
                    <Text>{balance ? formatDollar(balance) : 0}</Text>
                    <Text fontSize="xs" pb={3}>
                      CFA
                    </Text>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Stack>
    </Stack>
  );
};

export default HoldersDetailsTable;
