import React from "react";
import { Box, Heading, Stack, Text } from "@chakra-ui/react";

function Verification() {
  return (
    <Box w={{ base: 80, md: 108 }} color="brand.primary">
      <Stack spacing="3rem">
        <Heading as="h5" fontSize={{ base: "2xl", md: "3xl" }}>
          Your request has been sent
        </Heading>
        <Text fontSize={{ base: "lg", md: "xl" }}>
          The Ejara Team will review your account creation request and send you
          an email once verified.
        </Text>
      </Stack>
    </Box>
  );
}

export default Verification;
