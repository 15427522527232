// @flow
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../container/AuthLayout";
import Layout from "../container/Layout";
import Pages from "../pages";
import RequireAuth from "./RequireAuth";

function Router() {
  return (
    // <React.Suspense
    //   fallback={
    //     <Flex h="100vh" align="center" justify="center" direction="column">
    //       <Spinner
    //         thickness="4px"
    //         speed="0.65s"
    //         emptyColor="gray.200"
    //         color="blue.500"
    //         size="xl"
    //       />
    //       <Text>Loading...</Text>
    //     </Flex>
    //   }
    // >
    <Routes>
      {/* Public Route */}
      <Route element={<AuthLayout />}>
        <Route path="/auth/login" element={<Pages.LoginPage />} />
        <Route path="/auth/register" element={<Pages.RegisterPage />} />
        <Route
          path="/auth/forgot-password"
          element={<Pages.ForgotPasswordPage />}
        />
        <Route path="/auth/confirm" element={<Pages.VerificationPage />} />
        <Route
          path="/auth/reset-password"
          element={<Pages.ResetPasswordPage />}
        />
        {/* <Route path="/auth/verify" element={<Pages.VerifyPage />} /> */}

        {/* mfa */}
        <Route path="/auth/mfa" element={<Pages.Mfa />} />
        <Route path="/auth/verify" element={<Pages.MfaVerify />} />
      </Route>

      {/* Protected Route */}
      <Route element={<RequireAuth />}>
        <Route path="/" element={<Pages.Onboarding />} />

        <Route element={<Layout />}>
          <Route path="/bonds/:id" element={<Pages.Homepage />} />
          <Route path="activity" element={<Pages.ActivityPage />} />
          <Route path="profile" element={<Pages.ProfilePage />} />
          <Route path="profile/edit" element={<Pages.ProfileEditPage />} />
          <Route path="wallets" element={<Pages.WalletPage />} />
          <Route path="wallet/:address" element={<Pages.WalletDashboard />} />
          <Route
            path="wallet/:address/transaction/:id"
            element={<Pages.TransactionPage />}
          />
          <Route
            path="wallet/transaction/:id"
            element={<Pages.TransactionPage />}
          />
        </Route>
      </Route>
    </Routes>
    // </React.Suspense>
  );
}

export default Router;
