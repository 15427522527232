// import React from "react";

// const Homepage = React.lazy(() => import("./home"));
// const LoginPage = React.lazy(() => import("./login"));
// const RegisterPage = React.lazy(() => import("./register"));
// const ForgotPasswordPage = React.lazy(() => import("./forgot-password"));
// const ResetPasswordPage = React.lazy(() => import("./reset-password"));
// const VerificationPage = React.lazy(() => import("./verification"));
// const VerifyPage = React.lazy(() => import("./verify"));
// const ActivityPage = React.lazy(() => import("./activity"));
// const ProfilePage = React.lazy(() => import("./profile"));
// const ProfileEditPage = React.lazy(() => import("./profileEdit"));
// const WalletPage = React.lazy(() => import("./wallet"));
// const TransactionPage = React.lazy(() => import("./transaction"));
// const WalletDashboard = React.lazy(() => import("./wallet-dashboard"));
// const Onboarding = React.lazy(() => import("./onboarding"));
// const Bonds = React.lazy(() => import("./bonds"));

import Homepage from "./home";
import LoginPage from "./login";
import RegisterPage from "./register";
import ForgotPasswordPage from "./forgot-password";
import ResetPasswordPage from "./reset-password";
import VerificationPage from "./verification";
import VerifyPage from "./verify";
import ActivityPage from "./activity";
import ProfilePage from "./profile";
import ProfileEditPage from "./profileEdit";
import WalletPage from "./wallet";
import TransactionPage from "./transaction";
import WalletDashboard from "./wallet-dashboard";
import Onboarding from "./onboarding";
import Mfa from "./mfa";
import MfaVerify from "./mfa-verify";

const Pages = {
  Homepage,
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  VerificationPage,
  Mfa,
  MfaVerify,
  VerifyPage,
  ActivityPage,
  ProfilePage,
  ProfileEditPage,
  WalletPage,
  TransactionPage,
  WalletDashboard,
  Onboarding,
};

export default Pages;
