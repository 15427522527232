import React from "react";
import { HStack, Icon, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { BsCheck } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";

function WalletTextCopy({ walletAddress }: { walletAddress: string }) {
  const [value] = React.useState(walletAddress);
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <HStack textDecor="underline" fontWeight="semibold">
      <Text color="black">{value}</Text>{" "}
      <IconButton
        aria-label="Copy button"
        onClick={onCopy}
        variant="ghost"
        icon={<Icon as={hasCopied ? BsCheck : MdContentCopy} />}
      />
    </HStack>
  );
}

export default WalletTextCopy;
