import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  showTooltips: true,
  plugins: {
    legend: {
      // display: false,
      position: "bottom" as const,
      style: Pie,
    },
    tooltip: {
      bodyFont: {
        size: 14,
      },
    },
  },
};

export function PieChart({ data }: any) {
  return <Pie data={data} options={options} height={10} width={10} />;
}
