import React from "react";
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, Outlet } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { FiActivity } from "react-icons/fi";
import { HiOutlineLogout } from "react-icons/hi";

import Logo from "../assets/media/ejara.png";
import useAuth from "../hooks/useAuth";
import { useStore } from "../store";

function Layout() {
  const { signOut } = useAuth();

  const { bondId, getAllBonds } = useStore((state) => state);

  const bond = getAllBonds.find((bond: { id: any }) => bond.id === bondId);

  const id = bond ? bond.slug : "all";

  const menus = [
    {
      id: 1,
      name: "Dashboard",
      icon: AiFillDashboard,
      path: id ? `/bonds/${id}` : "/",
    },
    {
      id: 2,
      name: "Activity",
      icon: FiActivity,
      path: "/activity",
    },
    {
      id: 3,
      name: "Profile",
      icon: BsFillPersonFill,
      path: "/profile",
    },
  ];

  return (
    <Grid
      templateColumns={{ md: "4% 96%" }}
      pos="relative"
      h="100vh"
      overflow="hidden"
    >
      <GridItem h="100vh" display={{ base: "none", md: "block" }}>
        <HStack
          flexDirection="column"
          align="center"
          justify="space-between"
          bg="brand.primary"
          py={10}
          px={3}
          pos="fixed"
          h="100vh"
          as="aside"
        >
          <Stack spacing="3rem">
            <Image w={{ md: 16 }} src={Logo} alt="Ejara Logo" />

            <Stack spacing="2rem">
              {menus.map((menu) => (
                <Link
                  as={ReactRouterLink}
                  key={menu.id}
                  to={menu.path}
                  _hover={{ textDecor: "none", color: "white" }}
                  _active={{ color: "white", textDecor: "none" }}
                  _visited={{ color: "white" }}
                  color="white"
                >
                  <Stack align="center">
                    <Icon as={menu.icon} boxSize={6} />
                    <Text fontSize="sm">{menu.name}</Text>
                  </Stack>
                </Link>
              ))}
            </Stack>
          </Stack>

          <Stack
            as="button"
            role="button"
            aria-label="logout button"
            color="white"
            align="center"
            onClick={() => signOut()}
          >
            <Icon as={HiOutlineLogout} boxSize={6} />
            <Text>Logout</Text>
          </Stack>
        </HStack>
      </GridItem>
      <GridItem as="main" overflowY="scroll">
        <Box
          px={{ base: 4, xl: 20 }}
          pl={{ md: 20, xl: 20 }}
          pr={{ md: 8, xl: 20 }}
          py={{ base: 4, xl: 10 }}
        >
          <Outlet />
        </Box>
      </GridItem>
    </Grid>
  );
}

export default Layout;
