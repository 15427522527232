export function currentFormatter(amount: number) {
  return amount.toLocaleString("fr", {
    style: "currency",
    currency: "CFA",
    minimumFractionDigits: 2,
  });
}

export function formatDollar(number: number) {
  const p = number?.toFixed(2).split(".");
  return `${p[0]
    .split("")
    .reverse()
    .reduce((acc, num, i) => {
      return num + (num !== "-" && i && !(i % 3) ? "," : "") + acc;
    }, "")}`;
}
