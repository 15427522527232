import React from "react";
import { Divider, Stack } from "@chakra-ui/react";
import { DateRange } from "rsuite/DateRangePicker";
import TransactionItemMini from "../TransactionItemMini";
import { TransactionLoaderList } from "../TransactionLoader";
// eslint-disable-next-line import/no-cycle
import FilterAndButton from "../FilterAndButton";

// eslint-disable-next-line import/no-cycle
import { Transactions } from "../../pages/activity";

interface TransactionsProps {
  transactions: Transactions[];
  isLoading: boolean;
  value: DateRange | null;
  setValue: (value: DateRange | null) => void;
}

function TransactionsCard(props: TransactionsProps) {
  const { transactions, isLoading, value, setValue } = props;

  return (
    <Stack spacing={4}>
      <FilterAndButton
        wallet={transactions}
        setValue={setValue}
        value={value as DateRange}
      />

      <Stack
        divider={<Divider />}
        bg="white"
        rounded="md"
        h={{ md: 130 }}
        overflowY="scroll"
      >
        {isLoading ? (
          <TransactionLoaderList itemsToRender={10} />
        ) : (
          transactions?.map((item) => (
            <TransactionItemMini key={item.id} {...item} />
          ))
        )}
      </Stack>
    </Stack>
  );
}

export default TransactionsCard;
