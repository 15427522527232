import React from "react";
import { Box, Heading, Stack } from "@chakra-ui/react";
import TextInput from "../components/Form/FormInput";
import Button from "../components/Button";

function ResetPassword() {
  return (
    <Box w={{ base: 80, md: 108 }} color="brand.primary">
      <Stack>
        <Box>
          <Heading as="h5" fontSize={{ md: "3xl" }}>
            Create a new password
          </Heading>
        </Box>

        <form>
          <Stack
            spacing={{ base: "1rem", md: "2rem" }}
            mt={{ base: 8, md: 10 }}
          >
            <TextInput
              label="New password"
              placeholder="********"
              required
              type="password"
            />
            <TextInput
              label="Verify  Password"
              placeholder="********"
              required
              type="password"
            />

            <Button title="Save new password" />
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}

export default ResetPassword;
