import { createMutation, fetchQuery } from "../api";

const loginKey = "/login";
export const useLogin = createMutation({
  keysToRefetch: [loginKey],
  api: {
    baseUrl: "auth",
    url: "/api/v2/auth/login",
    method: "POST",
  },
});

const registerKey = "/register";
export const useRegister = createMutation({
  keysToRefetch: [registerKey],
  api: {
    baseUrl: "auth",
    url: "/api/v2/auth/signup",
    method: "POST",
  },
});

const confirmKey = "/confirm";
export const useConfirm = createMutation({
  keysToRefetch: [confirmKey],
  api: {
    baseUrl: "auth",
    url: "/api/v2/auth/sign-up/check-signup-details",
    method: "POST",
  },
});

const verifyKey = "/verify";
export const useVerify = createMutation({
  keysToRefetch: [verifyKey],
  api: {
    baseUrl: "auth",
    url: "/api/v3/auth/confirm-phone-number",
    method: "POST",
  },
});

const fetchBond = "/bonds";
export const useBondsFetch = fetchQuery({
  key: fetchBond,
  api: {
    baseUrl: "api",
    url: "/bonds ",
    method: "GET",
    params: { onError: { message: "Could not fetch bonds" } },
  },
});
