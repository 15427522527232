import React from "react";
import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";

function Button({
  colorScheme = "brandBlue",
  title,
  ...rest
}: {
  colorScheme?: string;
  title: string;
} & ButtonProps) {
  return (
    <ChakraButton h={{ base: 12, md: 14 }} colorScheme={colorScheme} {...rest}>
      {title}
    </ChakraButton>
  );
}

export default Button;
