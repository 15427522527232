import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useFormContext, Controller } from "react-hook-form";

function FormPhone({ name, label }: { name: string; label: string }) {
  const {
    control,
    formState: { isSubmitting, errors },
  } = useFormContext();
  const error = Array.isArray(errors[name])
    ? // @ts-ignore
      errors[name].join(", ")
    : errors[name]?.message || errors[name];

  return (
    <FormControl>
      <FormLabel fontSize="sm" fontWeight="bold">
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <PhoneInput
              country="cm"
              placeholder="24514875214"
              disabled={isSubmitting}
              {...field}
            />
          );
        }}
      />
      {error && (
        <FormErrorMessage fontSize="sm" role="alert" color="red.500">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

export default FormPhone;
