import { Flex, Heading, Input, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import useMfa from "../hooks/useMfa";

const Mfa = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState("");
  const verified = localStorage.getItem("verified");

  React.useEffect(() => {
    if (!state || verified) {
      navigate(-1);
    }
    // if (verified) {
    //   navigate(-1);
    // }
    // eslint-disable-next-line
  }, []);

  const {
    qrCode,
    data: activeMfas,
    error: activeMfaError,
    isLoading: activeMfaLoading,
    getAllActiveMfa,
  } = useMfa();

  const {
    data: verifyData,
    // error: verifyError,
    isLoading: verifying,
    verifyOtp,
  } = useMfa();

  const {
    data: validationData,
    // error: validationError,
    isLoading: validating,
    validateAuthenticator,
  } = useMfa();

  useEffect(() => {
    getAllActiveMfa();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (verifyData) {
      localStorage.setItem("verified", true);
      navigate("/");
    }
    // eslint-disable-next-line
  }, [verifyData]);

  useEffect(() => {
    if (validationData && !verified) {
      getAllActiveMfa();
    }
    // eslint-disable-next-line
  }, [validationData]);

  if (activeMfaLoading) {
    return <div>Loading...</div>;
  }

  if (activeMfaError) {
    return (
      <Flex align="center" flexDirection="column" justify="center">
        <p style={{ marginBottom: 15, color: "red" }}>aaa</p>
        <Button
          title="Try again"
          isLoading={false}
          isDisabled={false}
          // onClick={() => console.log("first")}
        />
      </Flex>
    );
  }

  if (activeMfas?.data.length > 0) {
    return (
      <Flex align="center" flexDirection="column" justify="center">
        <Heading>Verification Code</Heading>
        <p style={{ textAlign: "center", marginBottom: 15 }}>
          Enter the verification code from your <br /> authenticator app
        </p>
        <Flex align="center" flexDirection="column" justify="center">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyOtp({ data: [{ code: verifyCode }] });
            }}
          >
            <Input
              bg="#fff"
              autoComplete="false"
              type="number"
              name="code"
              id="code"
              style={{
                height: 40,
                marginBottom: 15,
                textAlign: "center",
                width: "100%",
              }}
              onChange={(e) => setVerifyCode(e.target.value)}
            />
            <Button
              type="submit"
              title="Verify"
              isLoading={verifying}
              isDisabled={verifying}
              style={{ width: "100%" }}
            />
          </form>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex align="center" flexDirection="column" justify="center">
      <Heading>Scan QR Code</Heading>
      <Text my={3}>Scan to register your device.</Text>
      <Flex align="center" flexDirection="column" justify="center">
        <img src={qrCode?.code} alt="qrcode" width={200} />
        <Stack>
          <Text my={3}>How it works</Text>
          <Text>1. Download google authenticator app</Text>
          <Text>2. Scan this QR code</Text>
          <Text>3. Enter the code generated below</Text>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validateAuthenticator({ code: verifyCode });
            }}
          >
            <Input
              autoComplete="false"
              type="number"
              name="code"
              id="code"
              bg="#fff"
              onChange={(e) => setVerifyCode(e.target.value)}
              style={{
                height: 40,
                marginBottom: 15,
                textAlign: "center",
                width: "100%",
              }}
            />
            <Button
              type="submit"
              title="Verify"
              isLoading={validating}
              isDisabled={validating}
              style={{ width: "100%" }}
            />
          </form>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default Mfa;
