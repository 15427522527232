import React from "react";
import { Divider, HStack, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { BsArrowDownRight, BsArrowUpLeft } from "react-icons/bs";
import { Link as RouterLink } from "react-router-dom";

import moment from "moment";
import Button from "./Button";
import { formatDollar } from "../utils/formatCurrency";

type TransactionItemMiniProps = {
  id: string | number;
  address: string;
  amount: number;
  transactiontype: string;
  transactiondate: string;
};

function TransactionItemMini(props: TransactionItemMiniProps) {
  const { id, address, amount, transactiondate, transactiontype } = props;

  const date = moment(transactiondate).utc().format("LLL");
  const fromNow = moment(transactiondate).fromNow();

  return (
    <Stack py={{ md: 3 }} px={3} divider={<Divider />}>
      <HStack align="center" justify="space-between">
        <HStack spacing="1rem">
          <Icon
            as={
              transactiontype === "withdrawal"
                ? BsArrowUpLeft
                : BsArrowDownRight
            }
            boxSize={5}
          />
          <Stack spacing="-0.2rem" pos="relative">
            <Text fontWeight="semibold">
              {transactiontype} of{" "}
              <Text
                as="span"
                color={
                  transactiontype === "withdrawal" ? "brand.red" : "brand.green"
                }
              >
                {transactiontype === "withdrawal" ? "-" : "+"}
                {formatDollar(amount)} CFA
              </Text>{" "}
              from
              <Text
                w={{ md: 60, xl: 56, "3xl": "full" }}
                fontSize={{ md: "sm", "2xl": "md" }}
                fontWeight="medium"
              >
                {address}
              </Text>
            </Text>
            <HStack color="gray.500" spacing="0.5rem" fontSize="xs">
              <Text>{date}</Text> <Text>-</Text> <Text>{fromNow}</Text>
            </HStack>
          </Stack>
        </HStack>

        <Link
          as={RouterLink}
          to={
            address
              ? `/wallet/${address}/transaction/${id}`
              : `/wallet/transaction/${id}`
          }
          _hover={{ textDecor: "none" }}
          _focus={{ outline: "none" }}
          textAlign="right"
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            title={
              transactiontype === "withdrawal"
                ? "Bond Withdrawal"
                : "Bond Purchase"
            }
            colorScheme={
              transactiontype === "withdrawal"
                ? "dangerColorScheme"
                : "brandGreen"
            }
            h={10}
            w={{ md: 32 }}
            fontSize="sm"
          />
        </Link>
      </HStack>
    </Stack>
  );
}

export default TransactionItemMini;
