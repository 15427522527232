import React from "react";
import { Flex, HStack, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { BsArrowDownRight, BsArrowUpLeft } from "react-icons/bs";
import moment from "moment";
import Button from "./Button";
import { formatDollar } from "../utils/formatCurrency";

export type TransactionItemProps = {
  id: number;
  address: string;
  amount: number;
  transactiondate: string;
  transactiontype: string;
};

export type WalletProps = {
  address: string;
  funds: number;
  prepaidInterest: number;
};

const captilize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function TransactionItem(props: TransactionItemProps) {
  const { address, transactiondate, transactiontype, id, amount } = props;

  const date = moment(transactiondate).utc().format("LLLL");
  const fromNow = moment(transactiondate).fromNow();

  return (
    <Link
      as={RouterLink}
      to={
        address
          ? `/wallet/${address}/transaction/${id}`
          : `/wallet/transaction/${id}`
      }
      _hover={{ textDecor: "none" }}
      _focus={{ outline: "none" }}
    >
      <Flex
        align="center"
        justify="space-between"
        py={{ md: 3, xl: 4 }}
        px={{ md: 4, xl: 8 }}
        w="full"
      >
        <HStack spacing="2rem">
          <Icon
            as={
              transactiontype === "withdrawal"
                ? BsArrowUpLeft
                : BsArrowDownRight
            }
            boxSize={{ lg: 4, xl: 7 }}
          />
          <Stack spacing="-0.2rem" pos="relative">
            <Text fontSize={{ sm: "sm", xl: "md" }}>
              {captilize(transactiontype)} of{" "}
              <Text
                as="span"
                color={
                  transactiontype === "withdrawal" ? "brand.red" : "brand.green"
                }
                fontWeight="semibold"
              >
                {transactiontype === "withdrawal" ? "-" : "+"}
                {formatDollar(amount)} CFA
              </Text>{" "}
              from{" "}
              <Text w={{ lg: 40 }} as="span" style={{ color: "#000" }}>
                {address}
              </Text>
            </Text>
            <HStack
              color="gray.500"
              spacing="0.5rem"
              fontSize={{ sm: "xs", xl: "sm" }}
            >
              <Text>{date}</Text> <Text>&#8211;</Text> <Text>{fromNow}</Text>
            </HStack>
          </Stack>
        </HStack>

        <Button
          title={
            transactiontype === "withdrawal"
              ? "Bond Withdrawal"
              : "Bond Purchase"
          }
          colorScheme={
            transactiontype === "withdrawal"
              ? "dangerColorScheme"
              : "brandGreen"
          }
          height={{ sm: 8, md: 10 }}
          w={{ sm: 56, xl: 48 }}
          fontSize={{ sm: "sm", xl: "md" }}
        />
      </Flex>
    </Link>
  );
}

export default TransactionItem;
