import React from "react";
import { Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import { Bars } from "react-loader-spinner";
import { BarChart } from "../Charts/BarChart";

function BarChartCard({
  title = "Pre-Paid interest",
  data,
  loading,
  ...rest
}: { title?: string; data: any; loading: boolean } & BoxProps) {
  return (
    <Stack>
      <Text
        textAlign="left"
        fontWeight="semibold"
        fontSize={{ md: "sm", xl: "lg" }}
        mt={14}
      >
        {title}
      </Text>
      <Box
        pos="relative"
        bg="white"
        p={3}
        rounded="md"
        w={{ md: "30vh", "2xl": "full" }}
        minH={{ md: 20, lg: 48, xl: 60, "5xl": 72, "6xl": 80 }}
        h={{ md: 20, lg: 48, xl: 60, "5xl": 72, "6xl": 80 }}
        {...rest}
      >
        <Stack spacing="0.5rem">
          <Text textAlign="right">2023</Text>
          {loading ? (
            <Bars
              height="100"
              width="max-content"
              color="#ff8000"
              ariaLabel="loading-indicator"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            />
          ) : (
            <BarChart data={data} />
          )}
        </Stack>
      </Box>
    </Stack>
  );
}

export default BarChartCard;
