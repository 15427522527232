import React from "react";
import { HStack, Text } from "@chakra-ui/react";

function PropertyItem({
  name,
  value,
}: {
  name: string;
  value: string | number;
}) {
  return (
    <HStack align="center" justify="space-between">
      <Text fontWeight="semibold">{name}</Text>
      <Text>{value}</Text>
    </HStack>
  );
}

export default PropertyItem;
