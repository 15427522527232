import { Flex, Grid, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { FaChartBar } from "react-icons/fa";
import { GiPayMoney, GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { IoMdEye } from "react-icons/io";
import { MdOutlinePointOfSale } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import UseDateFilter from "../../hooks/useDateFilter";
import { useFetch } from "../../hooks/useFetchEventSource";
import { useStore } from "../../store";
import Button from "../Button";
import BankCard from "../Cards/BankCard";

const LiveWalletSection = () => {
  // const { wallet, hasButton = false, width, hasDepositSize } = props;

  const { bondId } = useStore((state) => state);
  const { dateFilter } = UseDateFilter();
  const { startDate, endDate } = dateFilter;
  const { data: walletsMetrics } = useFetch({
    url: "wallets-stats",
    bondId,
    startDate,
    endDate,
  });
  const wallet = walletsMetrics[0] as any;
  // console.log(walletsMetrics);

  return (
    <Stack pos="relative" spacing="1rem">
      <Flex align="center" justify="space-between">
        <Text fontWeight="bold" fontSize={{ md: "sm", xl: "lg" }} m={0}>
          Live Wallet Metrics
        </Text>
        <Link as={RouterLink} to="/wallets" _hover={{ textDecor: "none" }}>
          <Button
            leftIcon={<Icon as={IoMdEye} boxSize={{ sm: 3, xl: 5 }} />}
            title="View All"
            colorScheme="orangeColorScheme"
            size="sm"
            fontSize={{ md: "xs", xl: "md" }}
            h={{ sm: 6, xl: 10 }}
          />
        </Link>
      </Flex>
      <Grid
        templateColumns={{
          md: "repeat(5, 1fr)",
        }}
        gap={4}
      >
        <BankCard
          icon={GiPayMoney}
          title="Total Depositors"
          amount={wallet?.totalDepositors}
          wallet
        />
        <BankCard
          icon={GiReceiveMoney}
          title="Total Withdrawers"
          amount={wallet?.totalWithdrawers}
          wallet
        />
        <BankCard
          icon={GiTakeMyMoney}
          title="Total Holders"
          count={wallet?.totalHolders}
        />
        {/* <BankCard
          icon={MdAccountBalanceWallet}
          title="Total Holders Balance"
          amount={wallet?.totalHoldersBalance}
        /> */}
        <BankCard
          icon={MdOutlinePointOfSale}
          title="Total Withdrawable Balance"
          amount={wallet?.totalHoldersWithdrawableBalance}
        />
        {/* <BankCard
          icon={FaCoins}
          title="Median Deposit size"
          amount={wallet?.medianDepositSize}
        /> */}
        <BankCard
          icon={FaChartBar}
          title="Average Deposit size"
          amount={wallet?.averageDepositSize}
        />
      </Grid>
    </Stack>
  );
};

export default LiveWalletSection;
