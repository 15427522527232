import { Box, Flex, Grid, Input, Stack, Text } from "@chakra-ui/react";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { AiFillDashboard } from "react-icons/ai";
import { DateRange } from "rsuite/DateRangePicker";
import FilterAndButton from "../components/FilterAndButton";
import { LoadingItemList } from "../components/LoadingItem";
import PageTitle from "../components/PageTitle";
import LiveWalletSectionMain from "../components/sections/LiveWalletSectionMain";
import { TransactionItemProps } from "../components/TransactionItem";
import TransactionItemMini from "../components/TransactionItemMini";
import { TransactionLoaderList } from "../components/TransactionLoader";
import TransactionTable from "../components/TransactionTable";
import useDateFilter from "../hooks/useDateFilter";
import { useFetch } from "../hooks/useFetchEventSource";
import { useStore } from "../store";

export default function Wallet() {
  const { bondId } = useStore((state) => state);
  const [offset, setOffset] = useState(0);

  const [filter, setFilter] = React.useState("");
  const { value, setValue, dateFilter } = useDateFilter();
  const { startDate, endDate } = dateFilter;

  const { status: walletsMetricsStatus, data: walletsMetrics } = useFetch({
    url: "wallets-stats",
    bondId,
    startDate,
    endDate,
  });

  const { status: walletsStatus, data: walletsData } = useFetch({
    url: "wallets",
    bondId,
    startDate,
    endDate,
  });

  const { status: walletTransactionStatus, data: walletTransactions } =
    useFetch({
      url: "wallets-transactions",
      bondId,
      startDate,
      endDate,
      limit: 10,
      offset: offset,
    });

  const handleChangeNext = () => {
    setOffset((prev) => prev + 10);
  };
  const handleChangePrev = () => {
    setOffset((prev) => prev - 10);
  };

  const wallet = walletsMetrics[0] as any;
  const wallets = walletsData[0] as any;
  const walletTransactionsData = walletTransactions[0] as any;

  return (
    <Stack spacing="2rem">
      <PageTitle title="EJARA Funded Wallets" icon={AiFillDashboard} />

      {walletsMetricsStatus === "fetching" ? (
        <Grid
          templateColumns={{ md: "repeat(5, 1fr)" }}
          gap={6}
          width={{ md: "70%" }}
        >
          <LoadingItemList itemsToRender={5} />
        </Grid>
      ) : (
        <LiveWalletSectionMain
          wallet={wallet}
          hasDepositSize
          width={{ md: "70%" }}
        />
      )}

      <Flex align="end" justify="space-between" w="full">
        <Stack w="35%">
          <Text fontSize={{ md: "xl" }} fontWeight="semibold">
            Ejara Wallets transactions
          </Text>
          <Input
            placeholder="Search by wallet address.."
            variant="filled"
            borderWidth={1}
            size="lg"
            borderColor="rgba(0, 117, 255, 0.2)"
            onChange={(e) => setFilter(e.target.value)}
          />
        </Stack>

        <FilterAndButton
          showTitle={false}
          wallet={wallet?.walletTransactions}
          value={value as DateRange}
          setValue={setValue}
        />
      </Flex>
      <Grid templateColumns={{ md: "51% 47%" }} gap={7} w="90vw">
        <Box
          bg="white"
          rounded="lg"
          p={{ md: 6 }}
          h={{ md: 122 }}
          overflowY="scroll"
        >
          <TransactionTable
            datas={wallets?.wallets}
            walletsMetricsStatus={walletsStatus}
          />
        </Box>
        <Box mt={2}>
          <Box
            bg="white"
            rounded="lg"
            p={{ md: 6 }}
            h={{ md: 122 }}
            overflowY="scroll"
          >
            {walletTransactionStatus === "fetching" ? (
              <TransactionLoaderList itemsToRender={6} />
            ) : (
              walletTransactionsData?.walletTransactions
                ?.filter((row: { transactiondate: string | number | Date }) => {
                  let filterPass = true;
                  const date = new Date(row.transactiondate);
                  if (dateFilter.startDate) {
                    filterPass =
                      filterPass && new Date(dateFilter.startDate) < date;
                  }
                  if (dateFilter.endDate) {
                    filterPass =
                      filterPass && new Date(dateFilter.endDate) > date;
                  }

                  return filterPass;
                })
                ?.filter((row: any) => row.address.includes(filter))
                .map((transaction: TransactionItemProps) => (
                  <TransactionItemMini key={transaction.id} {...transaction} />
                ))
            )}
          </Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "space-between" }}
            marginTop={2}
          >
            <Button
              variant="outlined"
              onClick={handleChangePrev}
              disabled={offset === 0}
            >
              Prev
            </Button>
            <Button
              variant="outlined"
              onClick={handleChangeNext}
              disabled={walletTransactionsData?.walletTransactions.length === 0}
            >
              Next
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Stack>
  );
}

Wallet.auth = true;
