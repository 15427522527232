import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme as muiCreateTheme,
  THEME_ID,
} from "@mui/material/styles";
import App from "./container/App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./assets/styles/theme";
import ErrorBoundary from "./utils/ErrorBoundary";

const queryClient = new QueryClient();
const materialTheme = muiCreateTheme();

const container = document.getElementById("root");
const root = createRoot(container as Element);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <MaterialThemeProvider theme={{ [THEME_ID]: materialTheme }}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </QueryClientProvider>
      </MaterialThemeProvider>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
