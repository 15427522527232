/* eslint-disable react/prop-types */
import React from "react";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import { formatDollar } from "../../utils/formatCurrency";

const Aggregates = ({ aggregate, status }) => {
  const {
    totalDepositsForLifetime,
    totalDepositsForLastMonth,
    totalDepositsForLastWeek,
    totalDepositsForYesterday,
  } = aggregate;

  return (
    <Box>
      <SimpleGrid spacing={10} columns={4}>
        <Card>
          <CardHeader bg="#505AE9">
            <Heading color="#ffffff" size="sm">
              Total Transactions - LifeTime
            </Heading>
          </CardHeader>
          <CardBody>
            {totalDepositsForLifetime
              ? formatDollar(totalDepositsForLifetime)
              : "---"}{" "}
            CFA{" "}
          </CardBody>
        </Card>
        <Card>
          <CardHeader bg="#505AE9">
            <Heading color="#ffffff" size="sm">
              {" "}
              Total Transactions - Last Month
            </Heading>
          </CardHeader>
          <CardBody>
            {totalDepositsForLastMonth
              ? formatDollar(totalDepositsForLastMonth)
              : "---"}{" "}
            CFA{" "}
          </CardBody>
        </Card>
        <Card>
          <CardHeader bg="#505AE9">
            <Heading color="#ffffff" size="sm">
              {" "}
              Total Transactions - Last Week
            </Heading>
          </CardHeader>
          <CardBody>
            {totalDepositsForLastWeek
              ? formatDollar(totalDepositsForLastWeek)
              : "---"}{" "}
            CFA{" "}
          </CardBody>
        </Card>
        <Card>
          <CardHeader bg="#505AE9">
            <Heading color="#ffffff" size="sm">
              {" "}
              Total Transactions - Yesterday
            </Heading>
          </CardHeader>
          <CardBody>
            {totalDepositsForYesterday
              ? formatDollar(totalDepositsForYesterday)
              : "---"}{" "}
            CFA{" "}
          </CardBody>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default Aggregates;
