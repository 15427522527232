import React from "react";
import { Box, ButtonProps, HStack, Link, Stack, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import Button from "../Button";
import WalletTextCopy from "../WalletTextCopy";

function InfoCard({
  title,
  btnTitle,
  cardTitle,
  cardSubtitle,
  link,
  address,
  ...rest
}: {
  title: string;
  btnTitle?: string;
  cardTitle: string;
  cardSubtitle?: string;
  link?: boolean;
  address?: string;
} & ButtonProps) {
  return (
    <Stack>
      <Text>{title}</Text>
      <Box
        bg="white"
        rounded="md"
        p={6}
        w={{ base: 80, md: 64 }}
        h={64}
        pos="relative"
      >
        <Stack spacing="3rem" mt={{ base: 10, md: 14 }}>
          <Box>
            <Text fontWeight="bold" fontSize={{ base: "xl", md: "2xl" }}>
              {cardTitle}
            </Text>
            {cardSubtitle && <Text fontSize="xs">{cardSubtitle}</Text>}
          </Box>

          <HStack justify="end" pos="absolute" bottom={10} right={4}>
            {link ? (
              <Link as={RouterLink} to="/profile/edit">
                <Button
                  title={btnTitle!}
                  w={{ base: 28, md: 32 }}
                  h={9}
                  rounded="lg"
                  {...rest}
                />
              </Link>
            ) : address ? (
              <WalletTextCopy walletAddress={address!} />
            ) : btnTitle ? (
              <Button
                title={btnTitle!}
                w={{ base: 28, md: 32 }}
                h={9}
                rounded="lg"
                {...rest}
              />
            ) : null}
          </HStack>
        </Stack>
      </Box>
    </Stack>
  );
}

export default InfoCard;
