import React from "react";
import { DateRange } from "rsuite/DateRangePicker";

const UseDateFilter = () => {
  const [value, setValue] = React.useState<DateRange | null>(null);

  let dateFilter = {
    startDate: "",
    endDate: "",
  };

  if (value) {
    dateFilter = {
      startDate: value[0].toISOString().split("T")[0],
      endDate: value[1].toISOString().split("T")[0],
    };
  }

  return { dateFilter, setValue, value };
};

export default UseDateFilter;
