import React from "react";
import { Heading, Stack, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import useForm from "../hooks/useForm";
import { useVerify } from "../utils/api";
import FormPinInput from "../components/Form/FormPinInput";
import { useStore } from "../store";

const Verify = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { signupData } = useStore((state) => state);
  const { phoneNumber, deviceId, countryCode, channel } = signupData;
  const [mutate, { isLoading: isSubmitting }] = useVerify({
    onSuccess: (vars) => {
      toast({
        title: vars.message,
        position: "bottom-right",
        status: "success",
        duration: 5000,
        isClosable: false,
      });
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      navigate("/auth/confirm");
    },
  });
  const onSubmit = async (values: any) => {
    await mutate(values);
  };
  const { renderForm } = useForm({
    onSubmit,
    defaultValues: {
      code: "",
      countryCode,
      channel,
      phoneNumber,
      deviceId,
    },
  });

  return renderForm(
    <Stack w={{ base: 80, md: 110 }} color="brand.primary">
      <Stack>
        <Heading as="h5" fontSize={{ md: "3xl" }}>
          Enter the code sent to your phone{" "}
          <Text as="span" fontWeight="light">
            to complete your registration
          </Text>
        </Heading>
      </Stack>
      <Stack spacing={{ base: "1rem", md: "2rem" }} mt={{ base: 8, md: 24 }}>
        <FormPinInput name="code" label="Code" placeholder="123456" />
        <Button type="submit" title="Confirm" isLoading={isSubmitting} />
      </Stack>
      <Button variant="ghost" title="Resend code" />
    </Stack>,
  );
};

export default Verify;
