import React from "react";
import { Grid, Stack, Text } from "@chakra-ui/react";
import { FaBuffer, FaMoneyBillWave, FaDonate } from "react-icons/fa";
import {
  AiOutlineFundProjectionScreen,
  AiOutlineProject,
} from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import { useParams } from "react-router-dom";
import BankCard from "../Cards/BankCard";

type Props = {
  metrics: {
    projectedEjaraInterestAtMaturity: number;
    projectedPrepaidInterestAtMaturity: number;
    totalTokenizedBonds: number;
    realtimePrepaidInterest: number;
    projectedInterestDistributedAtMaturity: number;
    totalSoldBonds: number;
  }[];
};

function BondMetricsSection(props: Props) {
  const { metrics } = props;
  const { id } = useParams();

  return (
    <Stack pos="relative" spacing="1rem" width="100%">
      <Text fontWeight="bold" fontSize={{ md: "sm", xl: "lg" }}>
        {id === "12" ? "OTA Tchad" : id === "13" ? "BTA Congo" : "All"} Bond
        Metrics
      </Text>
      <Grid templateColumns="repeat(3, 1fr)" gap={2}>
        <BankCard
          icon={FaBuffer}
          title="Ejara Supply"
          amount={metrics[0]?.totalTokenizedBonds}
          blockchain={0}
        />

        <BankCard
          icon={FaMoneyBillWave}
          title="Annual Projected Interest Earned"
          amount={metrics[0]?.projectedEjaraInterestAtMaturity}
          blockchain={0}
        />

        <BankCard
          icon={FaDonate}
          title="Annual Projected Interest Distributed"
          amount={metrics[0]?.projectedInterestDistributedAtMaturity}
          blockchain={0}
        />

        <BankCard
          icon={GiMoneyStack}
          title="Sold Supply"
          amount={metrics[0]?.totalSoldBonds}
          blockchain={0}
        />
        <BankCard
          icon={AiOutlineFundProjectionScreen}
          title="Prepaid interest distributed to Holders"
          amount={metrics[0]?.realtimePrepaidInterest}
          blockchain={0}
        />
        <BankCard
          icon={AiOutlineProject}
          title="Projected interest to be earned by Holders"
          amount={metrics[0]?.projectedPrepaidInterestAtMaturity}
          blockchain={0}
        />
      </Grid>
    </Stack>
  );
}

export default BondMetricsSection;
