import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "40rem", // 640px
  md: "48em", // 768
  lg: "62em", // 992
  xl: "80em", // 1280
  "2xl": "85.375em", // 1366
  "3xl": "90em", // 1440
  "4xl": "96em", // 1536
  "5xl": "108rem", // 1728
  "6xl": "120em", // 1920
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};

export const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        fontSize: "md",
        fontFamily: '"GoldmanSans", sans-serif',
        lineHeight: "tall",
        bg: "#E5E5E5",
        color: "#505AE9",
      },
    }),
  },
  breakpoints,
  fonts: {
    heading: '"GoldmanSans", sans-serif',
    body: '"GoldmanSans", sans-serif',
  },
  fontSizes: {
    xx: ".55rem",
    tiny: ".68rem",
    "7xl": "5rem",
    "8xl": "6rem",
  },
  colors: {
    brand: {
      primary: "#505AE9",
      background: "#E5E5E5",
      sidebar: "#060B26",
      blue: "#0075FF",
      purpleDark: "#855CF8",
      purpleLight: "#ACB9FF",
      green: "#01B574",
      red: "#E31A1A",
    },
    brandBlue: {
      500: "#505AE9",
      600: "#6F4DE9",
    },
    brandGreen: {
      500: "#01B574",
      600: "#01B574",
    },
    dangerColorScheme: {
      500: "#E31A1A",
      600: "#E31A1A",
    },
    orangeColorScheme: {
      500: "#ff8000",
      600: "#ff8000",
    },
  },
  space: {
    14: "3.5rem",
    60: "15rem",
    66: "17.5rem",
    70: "18rem",
    80: "20rem",
    82: "21rem",
    85: "23rem",
    90: "25rem",
    95: "26rem",
    108: "27rem",
    109: "28rem",
    110: "30rem",
    115: "32rem",
    120: "35rem",
    121: "36rem",
    122: "37rem",
    123: "40rem",
    125: "45rem",
    127: "48rem",
    130: "55rem",
    132: "57rem",
    135: "60rem",
    137: "65rem",
    139: "67rem",
    140: "70rem",
    145: "76rem",
    160: "97.5rem",
  },
  sizes: {
    14: "3.5rem",
    40: "10rem",
    60: "15rem",
    66: "17.5rem",
    70: "18rem",
    80: "20rem",
    82: "21rem",
    85: "23rem",
    90: "25rem",
    95: "26rem",
    108: "27rem",
    109: "28rem",
    110: "30rem",
    115: "32rem",
    117: "33rem",
    120: "35rem",
    121: "36rem",
    122: "37rem",
    123: "42rem",
    125: "45rem",
    127: "48rem",
    130: "55rem",
    132: "57rem",
    135: "60rem",
    137: "65rem",
    139: "67rem",
    140: "70rem",
    145: "76rem",
    160: "97.5rem",
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});
