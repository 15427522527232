import React from "react";
import { Skeleton, SkeletonText, Stack } from "@chakra-ui/react";

function LoadingItem() {
  return (
    <Stack
      spacing="4rem"
      padding="6"
      boxShadow="lg"
      bg="white"
      rounded="md"
      minW={{ md: 20, lg: 44, xl: 60, "2xl": 56, "5xl": 72, "6xl": 80 }}
      w={{ md: 20, xl: 60 }}
      minH={32}
      h={{ md: 28, lg: 48, xl: 60, "2xl": 56, "5xl": 72, "6xl": 80 }}
    >
      <SkeletonText mt="4" noOfLines={2} spacing="4" />
      <Skeleton height="14" />
      <Skeleton height="8" />
      {/* <SkeletonText mt="4" noOfLines={2} spacing="4" /> */}
    </Stack>
  );
}

export function LoadingItemList({ itemsToRender }: { itemsToRender: number }) {
  return (
    <>
      {Array(itemsToRender)
        .fill(1)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LoadingItem key={index} />
        ))}
    </>
  );
}
