import React, { Dispatch, SetStateAction } from "react";
import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import { Flex, HStack, Link, Text } from "@chakra-ui/react";
import Button from "./Button";
// eslint-disable-next-line import/no-cycle
import { Transactions } from "../pages/activity";

type Props = {
  wallet: Transactions[];
  value: DateRange;
  setValue: Dispatch<SetStateAction<DateRange | any>>;
  showTitle?: boolean;
};

const FilterAndButton = ({
  value,
  setValue,
  wallet,
  showTitle = true,
}: Props) => {
  return (
    <Flex align="center" justify="space-between" px={2}>
      {showTitle && (
        <Text fontWeight="bold" fontSize={{ md: "sm", xl: "lg" }}>
          Transactions
        </Text>
      )}
      <HStack spacing={6}>
        <DateRangePicker
          placeholder="Select Date Range"
          placement="auto"
          size="lg"
          value={value}
          onChange={setValue}
        />
        {wallet?.length > 0 && (
          <Link
            href={`https://tzkt.io/${process.env.REACT_APP_TRANSACTION_KEY}/tokens/1/transfers`}
            isExternal
            _hover={{ textDecor: "none" }}
          >
            <Button title="View on blockchain" h={10} />
          </Link>
        )}
      </HStack>
    </Flex>
  );
};

export default FilterAndButton;
