import axios from "axios";
import env from "../environments";

const httpInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
      "client-key": env.tokenizedBondenvironment.clientKey,
      "client-secret": env.tokenizedBondenvironment.clientSecret,
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      // const token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImRhdGVfY3JlYXRpb24iOjE2NzU3ODM4NTk2MTUsImRhdGVfdXBkYXRlIjoxNjc2NjI5MDQ2OTIzLCJpZCI6OTI5NDYsInVzZXJuYW1lIjoiZWphcmFKb2huIiwicGhvbmVOdW1iZXIiOm51bGwsInJlZmVyYWxDb2RlIjoiZWphci1DTS1lWSIsImxhc3RTZWVuQXQiOjE2NzY2MjkwNDY5MjMsInRvc0FjY2VwdGVkQnlJcCI6IjU0Ljg2LjUwLjEzOSIsInBob25lSWQiOiJiMjRmYzc5Mi0yYjhkLTRhNWMtOGY3Mi0xODI2OGUyZGEwMmUiLCJodWJzcG90U3luY2VkIjpmYWxzZSwiaW50ZXJjb21JZCI6bnVsbCwiY2FuU2VsbCI6ZmFsc2UsImNhbkJ1eSI6ZmFsc2UsImNhbkV4Y2hhbmdlIjpmYWxzZSwiY2FuQWNjZXNzTGl0ZSI6ZmFsc2UsImlzUGFydG5lciI6ZmFsc2UsImlzQWdlbnQiOmZhbHNlLCJpc1ZlcmlmaWVkIjpmYWxzZSwidmVyaWZpZWRBdCI6bnVsbCwiZW1haWxWZXJpZmllZCI6ZmFsc2UsImVtYWlsVmVyaWZpZWRBdCI6bnVsbCwicGhvbmVWZXJpZmllZCI6ZmFsc2UsInBob25lVmVyaWZpZWRBdCI6bnVsbCwiY3VzdG9tZXJUeXBlIjp7ImRhdGVfY3JlYXRpb24iOjE2MTMzOTU1NTMxNjMsImRhdGVfdXBkYXRlIjoxNjEzMzk1NTUzMTYzLCJpZCI6MywiZGVzY3JpcHRpb24iOiJhZG1pbiIsImlzRGVmYXVsdCI6ZmFsc2V9LCJjb3VudHJ5Ijo0MSwic3RhdHVzIjozLCJsYWJlbCI6MSwiZGV0YWlscyI6eyJkYXRlX2NyZWF0aW9uIjoxNjc1NzgzODU5NjI4LCJkYXRlX3VwZGF0ZSI6MTY3NTc4Mzg1OTYyOCwiaWQiOjkyNTQ4LCJmaXJzdE5hbWUiOiIiLCJsYXN0TmFtZSI6IiIsImdlbmRlciI6IiIsImVtYWlsQWRkcmVzcyI6ImpvaG4udGFua29AZWphcmEuYWZyaWNhIiwiZGF0ZV9vZl9iaXJ0aCI6bnVsbCwiaWRlbnRpZmljYXRpb24iOm51bGwsInByZWZlcnJlZExhbmd1YWdlIjoiZnIiLCJpbkFwcE5vdGlmaWNhdGlvbnMiOnRydWUsImFwcFNjcmVlbnNob3RzIjpmYWxzZSwibW9vbnBheUN1c3RvbWVySWQiOm51bGwsImRldGFpbHMiOm51bGwsImN1c3RvbWVyIjo5Mjk0NiwicHJlZmVycmVkRmlhdEN1cnJlbmN5IjozNX0sInJlZmVycmVyIjpudWxsLCJtYW5hZ2VyIjpudWxsLCJjcmVhdGVkQnkiOm51bGwsImxveWFsdHlMZXZlbCI6bnVsbH0sImlhdCI6MTY3NjYzMjUxOCwiZXhwIjoxNjc2NjMzNzk3LCJhdWQiOiJlamFyYS5pbyIsImlzcyI6ImVqYXJhLmlvIn0.VbLDbhbSgbLib-c8SYhTCNJ8kQ0MNgQDaAV8F-9GqNc";
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/login" && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const headers = {
            "Content-Type": "application/json",
            "client-id": process.env.REACT_APP_CLIENT_ID,
            "api-key": process.env.REACT_APP_API_KEY,
          };
          try {
            const rs = await axios.post(
              "https://prodbox-nellys-coin.ejaraapis.xyz/api/v1/auth/reset-token",
              {
                refreshToken: localStorage.getItem("refresh_token"),
              },
              { headers },
            );
            // console.log(rs);

            const newToken = rs?.data?.data.token;
            localStorage.setItem("token", newToken);
            return await instance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    },
  );

  return instance;
};

export default httpInstance;
