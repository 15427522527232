import {
  Box,
  Link as ChakraLink,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { AiTwotonePieChart } from "react-icons/ai";
import { BsFolder } from "react-icons/bs";
import { RiUser6Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import useBonds from "../hooks/useBonds";
import { useStore } from "../store";
import { slugify } from "../utils/constants";

import BgImageSVG from "../assets/media/bg.svg";

export type Bond = {
  id: number;
  slug: string;
  name: string;
  icon: any;
  bg: string;
  roundedbg: string;
  interest: string;
  availability: string;
  date: string;
};

const Onboarding = () => {
  // const { state } = useLocation();
  const { loading, bonds, error } = useBonds();

  const onboardingData: Bond[] = useMemo(() => {
    return bonds?.map((bond) => {
      return {
        id: bond.id,
        slug: slugify(bond.name),
        name: bond.name,
        icon: bond.name === "OTA Tchad" ? RiUser6Fill : AiTwotonePieChart,
        bg: bond.name === "OTA Tchad" ? "#F24C4C" : "#242F9B",
        roundedbg: bond.name === "OTA Tchad" ? "#36AE7C" : "#F24C4C",
        interest: `${bond.interestrate}%`,
        availability: bond.name === "OTA Tchad" ? "Available" : "Not Available",
        date: bond.maturitydate,
      };
    });
  }, [bonds]);

  useEffect(() => {
    useStore.setState({ getAllBonds: onboardingData });
  }, [onboardingData]);

  // const fetchNewToken = async () => {
  //   const headers: any = {
  //     "Content-Type": "application/json",
  //     "client-id": process.env.REACT_APP_CLIENT_ID,
  //     "api-key": process.env.REACT_APP_API_KEY,
  //   };
  //   try {
  //     const rs = await axios.post(
  //       "https://prodbox-nellys-coin.ejaraapis.xyz/api/v1/auth/reset-token",
  //       {
  //         refreshToken: localStorage.getItem("refresh_token"),
  //       },
  //       { headers },
  //     );

  //     const newToken = rs?.data?.data.token;
  //     localStorage.setItem("token", newToken);
  //   } catch (error) {
  //     const err = error;
  //   }
  // };

  if (error) {
    return <Text>There is an error while fetching the bonds data</Text>;
  }

  // if (state?.tokenExpired) {
  //   return <Text>Token is expired</Text>;
  // }

  return (
    <Flex h="100vh" w="100vw" justify="center" align="center" color="gray.700">
      {loading ? (
        <Text>loading bonds...</Text>
      ) : (
        <Stack spacing={10}>
          <Heading textAlign="center">Choose a bond</Heading>
          <HStack spacing={4}>
            {onboardingData?.map((item) => (
              <ChakraLink
                as={Link}
                key={item.id}
                to={`/bonds/${item.slug}`}
                state={{ id: item.id }}
                aria-disabled={bonds.length === 0}
                sx={{
                  pointerEvents: bonds.length === 0 ? "none" : "auto",
                }}
                _hover={{ textDecor: "none" }}
              >
                <Stack
                  as="button"
                  pos="relative"
                  justifyContent="space-between"
                  rounded="lg"
                  p={5}
                  w={{ md: 64 }}
                  minH={{ md: 108 }}
                  textAlign="left"
                  spacing={5}
                  _hover={{ borderColor: "brand.primary" }}
                  transition="all 0.2s ease-in-out"
                  bg={item.bg}
                  color="white"
                  onClick={() => useStore.setState({ bondId: item.id })}
                >
                  <Box pos="absolute" opacity={0.5}>
                    <Image h="full" src={BgImageSVG} />
                  </Box>
                  <Stack w="full">
                    <Flex align="center" justify="space-between">
                      <Flex
                        align="center"
                        justify="center"
                        w={10}
                        h={10}
                        rounded="full"
                        bg="white"
                        color="brand.primary"
                      >
                        <Icon as={BsFolder} boxSize={6} />
                      </Flex>
                      <Box py={1} px={2} rounded="full" bg={item.roundedbg}>
                        <Text fontSize="xs" fontWeight="semibold">
                          {item.availability}
                        </Text>
                      </Box>
                    </Flex>
                    <Text fontWeight="medium">{item.name}</Text>
                  </Stack>
                  <Stack spacing={-0.5}>
                    <Text fontSize="sm">Maturity</Text>
                    <Text fontWeight="bold">
                      {moment(item.date).format("LL")}
                    </Text>
                  </Stack>
                  <Stack spacing={-0.5}>
                    <Text fontSize="sm">Interest rate</Text>
                    <Text fontWeight="extrabold" fontSize="lg">
                      {parseFloat(item.interest).toFixed(1)}
                    </Text>
                  </Stack>
                </Stack>
              </ChakraLink>
            ))}
          </HStack>

          {bonds?.length > 1 && (
            <ChakraLink
              as={Link}
              to="/bonds"
              display="block"
              _hover={{ textDecor: "none" }}
            >
              <Button title="I want to see All data" w="full" />
            </ChakraLink>
          )}
        </Stack>
      )}
    </Flex>
  );
};

export default Onboarding;
