import React from "react";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { PieChart } from "../Charts/PieChart";

function PieChartCard({ custodianBackers, loading }: any) {
  const names = custodianBackers.map((backer: any) => backer.name);
  const amounts = custodianBackers.map((backer: any) => backer.amount);

  const data = {
    labels: names,
    datasets: [
      {
        data: amounts,
        backgroundColor: ["#ff8000"],
        borderColor: ["#ff8000"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Stack spacing="1rem">
      <Text fontWeight="bold" fontSize={{ md: "sm", xl: "lg" }}>
        Custodian Ownership
      </Text>
      <Flex
        align="center"
        justify="center"
        bg="white"
        p={6}
        rounded="md"
        // minW={{ lg: 40, xl: 60, "5xl": 72, "6xl": 80 }}
        // w={{ lg: 40, xl: 60 }}
        // minH={{ lg: 40, xl: 60 }}
        minH={{ lg: 40, xl: 60, "5xl": 72, "6xl": "40rem" }}
      >
        {loading ? <Box className="pie" /> : <PieChart data={data} />}
      </Flex>
    </Stack>
  );
}

export default PieChartCard;
