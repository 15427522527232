import React, { FC } from "react";
import { Text, Stack, VStack } from "@chakra-ui/react";
import { IoIosRefresh } from "react-icons/io";
// eslint-disable-next-line import/no-self-import
import Button from "../Button";

interface IProps {
  name?: string;
  message?: string;
  stack?: string;
  reload(): void;
  size?: "sm" | "md";
  error?: string;
}

const ReloadButton: FC<IProps> = ({
  size = "md",
  name,
  message,
  stack,
  reload,
  error,
}) => {
  const decodeStack = stack
    ?.split("\n")
    .map((line) => decodeURIComponent(line));

  stack = decodeStack?.join("\n")?.split("at ")[1]?.split(" ")[0];

  return (
    <VStack>
      {process.env.NODE_ENV === "development" ? (
        <Stack spacing="0.3rem" textAlign="center">
          <Text fontSize={size} color="red.500" fontWeight="semibold">
            {name || ""}
          </Text>
          <Text fontWeight="semibold" fontSize="lg">
            {message}
          </Text>
          <Text fontSize={size}>at {stack}</Text>
        </Stack>
      ) : (
        <Text fontSize={size}>
          {error ?? "Something went wrong. Contact support"}
        </Text>
      )}
      <Button
        size="md"
        fontSize="md"
        rounded="20px"
        onClick={reload}
        title={size === "md" ? "Try again" : ""}
        leftIcon={<IoIosRefresh fontSize="lg" />}
      />
    </VStack>
  );
};

export default ReloadButton;
