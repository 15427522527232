import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

function AuthTitle({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Box>
      <Text fontSize={{ base: "lg", md: "xl" }}>{title}</Text>
      <Heading as="h5" fontSize={{ base: "2xl", md: "3xl" }}>
        {subtitle}
      </Heading>
    </Box>
  );
}

export default AuthTitle;
