import { Box, Grid, Link, Stack, useToast } from "@chakra-ui/react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import React from "react";
import { useQuery } from "react-query";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import AuthPageTitle from "../components/AuthPageTitle";
import Button from "../components/Button";
import { FormInput } from "../components/Form/FormInput";
import FormPhone from "../components/Form/FormPhone";
import FormSelect from "../components/Form/FormSelect";
import useAPI from "../hooks/useAPI";
import useForm from "../hooks/useForm";
import { useStore } from "../store";
import { useConfirm, useRegister } from "../utils/api";

// import { Signup } from "../utils/validations";

function Register() {
  const toast = useToast();
  const { setSignupData } = useStore((state) => state);
  const { fetchCountryAPI } = useAPI();
  const { data: countriesData, isLoading } = useQuery(
    "countries",
    fetchCountryAPI,
  );

  const navigate = useNavigate();
  const [visitorId, setVisitorId] = React.useState();

  React.useEffect(() => {
    const fpPromise = FingerprintJS.load();
    const getDeviceId = async () => {
      const fp = await fpPromise;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { visitorId } = await fp.get();
      // @ts-ignore
      setVisitorId(visitorId);
    };
    getDeviceId();
  }, []);

  const [mutate, { isLoading: muationLoading }] = useConfirm({
    onSuccess: (vars) => {
      toast({
        title: vars.message,
        position: "bottom-right",
        status: "success",
        duration: 5000,
        isClosable: false,
      });
    },
  });

  const [mutation, { isLoading: isSubmitting }] = useRegister({
    onSuccess: (vars) => {
      toast({
        title: vars.message,
        position: "bottom-right",
        status: "success",
        duration: 5000,
        isClosable: false,
      });
      navigate("/auth/verify");
    },
  });

  const onSubmit = async (values: any) => {
    const sendData = {
      ...values,
      phone_id: values.phone_number,
      verifiedBy: "phone",
    };
    const verificationData = {
      username: values.username,
      emailAddress: values.email_address,
      phoneNumber: values.phone_number,
      countryCode: values.country_code,
      deviceId: visitorId,
    };
    setSignupData({
      phoneNumber: values.phone_number,
      deviceId: visitorId,
      countryCode: values.country_code,
      channel: "phone",
    });
    await mutate(verificationData as any);
    await mutation(sendData);
  };

  const { renderForm, watchForm } = useForm({
    onSubmit,
    defaultValues: {
      username: "",
      firstName: "",
      lastName: "",
      email_address: "",
      country_code: "cm",
      phone_number: "",
      phone_id: "",
      language: "en",
      password: "",
      gender: "",
      customerType: "government",
    },
    // schema: Signup,
  });

  const getCountryName = countriesData?.map(
    (country: { name: { common: string }; cca2: string }) => {
      return {
        countryName: country.name.common,
        countryCode: country.cca2,
      };
    },
  );

  const getAllCountries = getCountryName
    ?.map((country: { countryName: any }) => country.countryName)
    .sort();

  const watchUserName = watchForm("username");
  const watchFirstName = watchForm("firstName");
  const watchLastName = watchForm("lastName");
  const watchEmail = watchForm("email_address");
  const watchGender = watchForm("gender");
  const watchCountryCode = watchForm("country_code");
  const watchPhoneNumber = watchForm("phone_number");
  const watchPassword = watchForm("password");

  const disabledFields =
    !watchUserName ||
    !watchFirstName ||
    !watchLastName ||
    !watchEmail ||
    !watchGender ||
    !watchCountryCode ||
    !watchPhoneNumber ||
    !watchPassword;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return renderForm(
    <Box w={{ base: 80, md: 123 }} color="brand.primary">
      <Stack spacing="2rem">
        <AuthPageTitle
          title="Welcome to Ejara,"
          subtitle=" Add your details to create your account"
        />

        <Stack spacing="1rem">
          <Grid templateColumns={{ md: "repeat(2, 1fr)" }} gap={6}>
            <FormInput
              name="username"
              label="Username"
              placeholder="Frida Kahlo de Rivera"
            />
            <FormInput name="firstName" label="First Name" placeholder="John" />
            <FormInput name="lastName" label="Last Name" placeholder="Doe" />
            <FormInput
              name="email_address"
              type="email"
              label="Email"
              placeholder="m@example.com"
            />
            <FormSelect
              name="gender"
              label="Gender"
              options={["male", "female", "other"]}
            />
            <FormSelect
              name="country_code"
              label="Country code"
              countryCode={getCountryName}
              options={getAllCountries}
            />
            <FormPhone name="phone_number" label="Phone Number" />
            <FormInput
              name="password"
              label="Password"
              placeholder="********"
              type="password"
            />
          </Grid>

          <Button
            title="Create my account"
            type="submit"
            isLoading={isSubmitting || muationLoading}
            isDisabled={isSubmitting || isLoading || disabledFields}
          />
        </Stack>

        <Link
          as={ReactLink}
          to="/auth/login"
          _hover={{ textDecor: "none" }}
          _focus={{ outline: "none" }}
          fontSize="lg"
          fontWeight="semibold"
        >
          Already have an account? Login now{" "}
        </Link>
      </Stack>
    </Box>,
  );
}

export default Register;
