import React from "react";
import {
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatDollar } from "../utils/formatCurrency";

type CityTableProps = {
  wallet: {
    averageDepositSize: number;
    totalHolders: number;
    valueOfFundedWallets: number;
  };
};
const CityTable = (props: CityTableProps) => {
  const { wallet } = props;

  return (
    <Table
      variant="simple"
      bg="white"
      w="full"
      maxH={{ md: 120 }}
      overflowY="scroll"
      rounded="md"
    >
      <Thead bg="brand.primary" h={{ md: 16 }}>
        <Tr>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            Country
          </Th>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            City
          </Th>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            Active Wallets
          </Th>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            TVL
          </Th>
          <Th
            color="white"
            fontSize="md"
            textTransform="capitalize"
            fontWeight="semibold"
          >
            ATPU
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Cameroon</Td>
          <Td>Douala</Td>
          <Td>{wallet?.totalHolders || 0}</Td>
          <Td>
            <HStack spacing="0.1rem">
              <Text>
                {wallet ? formatDollar(wallet?.valueOfFundedWallets || 0) : 0}
              </Text>
              <Text fontSize="xs" pb={3}>
                CFA
              </Text>
            </HStack>
          </Td>
          <Td>
            <HStack spacing="0.1rem">
              <Text>
                {wallet ? formatDollar(wallet?.averageDepositSize || 0) : 0}
              </Text>
              <Text fontSize="xs" pb={3}>
                CFA
              </Text>
            </HStack>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
export default CityTable;
