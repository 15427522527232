/* eslint-disable */
import React from "react";
import {
  // FormControl,
  // FormErrorMessage,
  FormLabel,
  Input,
  // Input,
} from "@chakra-ui/react";
import Select, {
  CSSObjectWithLabel,
  ValueContainerProps,
  components,
} from "react-select";
import { Controller, useFormContext } from "react-hook-form";

type Props = {
  /** Field name. */
  name: string;
  /** Field label. */
  label?: string;
  /** Field options. */
  options: string[];
  /** Field isMulti. */
  isMulti?: boolean;
  placeholder?: string;
  countryCode?: any;
};

type Error = {
  error: [string];
};

const DataListSelect = ({
  name,
  label,
  options,
  isMulti,
  placeholder,
  countryCode,
}: Props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const error = Array.isArray(errors[name])
    ? // @ts-ignore
      errors[name]?.join(", ")
    : errors[name]?.message || errors[name];

  const getOptions = (options: any) => {
    return options.map((option: any) => ({
      value: option,
      label: option,
    }));
  };

  const optionsList = getOptions(options);

  const getCountryCode = (cc: any) => {
    return countryCode?.filter((code: any) => code?.countryName === cc);
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps) => (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );

  return (
    <form>
      {label && <label htmlFor="data-list-select">{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => {
          const handleSelectChange = (selectedOption: any) => {
            isMulti
              ? onChange(selectedOption.map((option: any) => option.value))
              : countryCode
              ? onChange(getCountryCode(selectedOption.value)[0].countryCode)
              : onChange(selectedOption.value);
          };

          return (
            <>
              <Select
                // inputRef={ref}
                // as={Select}
                // bg="white"
                // borderWidth={1}
                // borderColor="brand.primary"
                // _hover={{ borderColor: "brand.primary" }}
                // _focus={{ borderColor: "brand.primary" }}
                // height={16}
                // w="full"
                // display="flex"
                // alignItems="center"
                // textTransform="capitalize"
                placeholder={placeholder}
                options={getOptions(options)}
                value={
                  isMulti
                    ? optionsList.filter((c: { value: any }) =>
                        value.includes(c.value),
                      )
                    : optionsList.find((c: { value: any }) => c.value === value)
                }
                onChange={handleSelectChange}
                isMulti={isMulti}
                components={{
                  ValueContainer,
                  IndicatorSeparator: null,
                  ClearIndicator: () => null,
                }}
                styles={
                  {
                    // valueContainer: (base) => ({
                    //   ...base,
                    //   fontSize: "14px",
                    //   width: "100%",
                    // }),
                    // singleValue: (provided) => ({
                    //   ...provided,
                    //   color: "#0075FF",
                    //   width: "100%",
                    // }),
                    // control: (provided) => ({
                    //   ...provided,
                    //   width: "100%",
                    //   border: "none",
                    //   boxShadow: "none",
                    //   backgroundColor: "none",
                    //   "&:hover": {
                    //     borderColor: "none",
                    //   },
                    // }),
                  }
                }
              />
            </>
          );
        }}
      />
      {error && (
        <span role="alert" color="red.500">
          {error as string}
        </span>
      )}
    </form>
  );
};

export default DataListSelect;
