import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";

const useMfa = () => {
  const toast = useToast();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState({});
  const baseUrl = process.env.REACT_APP_MFA_API_URL;
  const baseAuthUrl = process.env.REACT_APP_AUTH_URL;

  const token = `Bearer ${localStorage.getItem("token")}`;

  const config = {
    headers: {
      "client-key": process.env.REACT_APP_MFA_CLIENT_KEY,
      "client-secret": process.env.REACT_APP_MFA_CLIENT_SECRET,
      Authorization: token,
    },
  };

  const configNc = {
    headers: {
      "api-Key": process.env.REACT_APP_API_KEY,
      "client-id": process.env.REACT_APP_CLIENT_ID,
    },
  };

  const setupAuthenticator = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/mfa/setup-authenticator`,
        data,
        config,
      );
      // console.log(response?.data?.data);
      setQrCode({
        code: response?.data?.data?.qrCodeUri,
        key: response?.data?.data?.setupKey,
      });
    } catch (error) {
      setData(null);
      toast({
        title: error?.response?.data.message,
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: false,
      });
    }
    setIsLoading(false);
  };

  const getAllActiveMfa = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/mfa/active`, config);
      if (response?.data?.data.length <= 0) {
        await setupAuthenticator();
      }
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error?.response?.data);
      setData(null);
    }
    setIsLoading(false);
  };

  const validateAuthenticator = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/mfa/validate-authenticator`,
        data,
        config,
      );
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error?.response?.data);
      setData(null);
      toast({
        title: error?.response?.data.message,
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: false,
      });
    }
    setIsLoading(false);
  };

  const verifyOtp = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/mfa/verify`, data, config);
      setData(response.data);
      setError(null);
      toast({
        title: response.data.message,
        position: "top-right",
        status: "success",
        duration: 5000,
        isClosable: false,
      });
    } catch (error) {
      setError(error?.response?.data);
      setData(null);
      toast({
        title: error?.response?.data.message,
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: false,
      });
    }
    setIsLoading(false);
  };

  const confirmyOtpCode = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseAuthUrl}/api/v1/auth/confirm-mfa-code`,
        data,
        configNc,
      );
      setData(response.data.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      // localStorage.setItem("verified", "true");

      setError(null);
      toast({
        title: response.data.message,
        position: "top-right",
        status: "success",
        duration: 5000,
        isClosable: false,
      });
    } catch (error) {
      setError(error?.response?.data);
      setData(null);
      toast({
        title: error?.response?.data.message,
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: false,
      });
    }
    setIsLoading(false);
  };

  return {
    qrCode,
    data,
    error,
    isLoading,
    getAllActiveMfa,
    validateAuthenticator,
    verifyOtp,
    confirmyOtpCode,
  };
};
export default useMfa;
