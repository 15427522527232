import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  PropsWithoutRef,
} from "react";
import { PinInput, PinInputField } from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "@chakra-ui/input";

export interface FormPinInputProps
  extends ComponentPropsWithoutRef<typeof Input> {
  /** Field name. */
  name: string;
  /** Field label. */
  label?: string;
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  labelProps?: ComponentPropsWithoutRef<"label">;
  props?: ComponentPropsWithoutRef<typeof Input>;
  required?: boolean;
}
const FormPinInput = forwardRef<HTMLInputElement, FormPinInputProps>(
  ({ name, label, required, outerProps, labelProps }, ref) => {
    const {
      control,
      formState: { isSubmitting, errors },
    } = useFormContext();
    // eslint-disable-next-line
    const error = Array.isArray(errors[name])
      ? // @ts-ignore
        errors[name].join(", ")
      : errors[name]?.message || errors[name];
    return (
      <FormControl ref={ref} isRequired={required} {...outerProps}>
        {label && (
          <FormLabel fontSize="sm" fontWeight="bold" {...labelProps}>
            {label}
          </FormLabel>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <PinInput otp {...field} isDisabled={isSubmitting} size="lg">
              <PinInputField
                borderColor="primary"
                bg="white"
                _hover={{ borderColor: "primary" }}
                _focus={{ borderColor: "primary" }}
              />
              <PinInputField
                borderColor="primary"
                bg="white"
                _hover={{ borderColor: "primary" }}
                _focus={{ borderColor: "primary" }}
              />
              <PinInputField
                borderColor="primary"
                bg="white"
                _hover={{ borderColor: "primary" }}
                _focus={{ borderColor: "primary" }}
              />
              <PinInputField
                borderColor="primary"
                bg="white"
                _hover={{ borderColor: "primary" }}
                _focus={{ borderColor: "primary" }}
              />
              <PinInputField
                borderColor="primary"
                bg="white"
                _hover={{ borderColor: "primary" }}
                _focus={{ borderColor: "primary" }}
              />
              <PinInputField
                borderColor="primary"
                bg="white"
                _hover={{ borderColor: "primary" }}
                _focus={{ borderColor: "primary" }}
              />
            </PinInput>
          )}
        />
      </FormControl>
    );
  },
);
export default FormPinInput;
