import React, { useMemo } from "react";
import axios from "axios";
import http from "../utils/httpIntance";
import {
  APIContextType,
  Bonds,
  TransactionData,
  TransactionHash,
  Users,
  WalletData,
} from "../types/API.interface";
// import useAuth from "../hooks/useAuth";
import { ResponsePayload } from "../types/Auth.interface";

export const APIContext = React.createContext<APIContextType | null>(null);

export const APIProvider = ({ children }: any) => {
  // const { isAuthenticated } = useAuth();

  const getUsers = async () => {
    const url = "https://prodbox-tokenized-bonds.ejaraapis.xyz/api/v1/";
    const axiosInstance = http(url);
    let res: ResponsePayload<string, Users[]> = {};
    res = await axiosInstance.get("dashboard/holders-details");
    return res;
  };

  const getBonds = async () => {
    const axiosInstance = http(`${process.env.REACT_APP_API_URL}`);
    let res: ResponsePayload<string, Bonds[]> = {};
    res = await axiosInstance.get("/bonds");
    return res;
  };

  const getWalletAddress = async (address: string | undefined) => {
    const axiosInstance = http(`${process.env.REACT_APP_API_URL}`);
    let res: ResponsePayload<string, WalletData> = {};
    res = await axiosInstance.get(`/wallet/${address}`);
    return res;
  };

  const getTransaction = async (id: number | undefined) => {
    const axiosInstance = http(`${process.env.REACT_APP_API_URL}`);
    let res: ResponsePayload<string, TransactionData> = {};
    res = await axiosInstance.get(`/transaction/${id}`);
    return res;
  };

  const getTransactionHash = async (transactionHash: string | undefined) => {
    const axiosInstance = http(`${process.env.REACT_APP_HASH_API_URL}`);
    let res: ResponsePayload<string, TransactionHash[]> = {};
    res = await axiosInstance.get(`/transaction/${transactionHash}`);
    return res;
  };

  const fetchCountryAPI = async () => {
    const { data } = await axios.get("https://restcountries.com/v3.1/all");
    return data;
  };

  const getAllTransactions = async () => {
    const axiosIntance = http(`${process.env.REACT_APP_API_URL}`);
    let res: ResponsePayload<string, TransactionHash[]> = {};
    res = await axiosIntance.get("/transactions");
    return res;
  };

  const provider = useMemo(
    (): {
      getWalletAddress: (
        address: string | undefined,
      ) => Promise<ResponsePayload<string, WalletData>>;
      getTransaction: (
        id: number | undefined,
      ) => Promise<ResponsePayload<string, TransactionData>>;
      getTransactionHash: (
        id: string | undefined,
      ) => Promise<ResponsePayload<string, TransactionHash[]>>;
      fetchCountryAPI: () => Promise<any>;
      getUsers: () => Promise<ResponsePayload<string, Users[]>>;
      getBonds: () => Promise<ResponsePayload<string, Bonds[]>>;
      getAllTransactions: () => Promise<
        ResponsePayload<string, TransactionHash[]>
      >;
    } => ({
      getWalletAddress,
      getTransaction,
      getTransactionHash,
      fetchCountryAPI,
      getBonds,
      getUsers,
      getAllTransactions,
    }),
    [],
  );

  return <APIContext.Provider value={provider}>{children}</APIContext.Provider>;
};
